import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Autocomplete, Card, Stack, TextareaAutosize, TextField, useTheme } from '@mui/material';

import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { DeleteIcon } from '../../../icons';
import { useFormik } from 'formik';
import { DeviItem, Product } from '../../../../services_api';
import { useEffect, useTransition } from 'react';
import { LineDevisType } from './panels/activity-panel/modals';
import debounce from 'lodash/debounce';
import { MonetaryNumberField } from '../../../forms';



type LigneDevisProps = {
    data: LineDevisType;
    autoCompleetOptions?: Product[];
    onDelete: () => void;
    onUpdate: (id: string, data: LineDevisType) => void;
}
export default function LigneDevis(props: LigneDevisProps) {
    const { data, onDelete, onUpdate, autoCompleetOptions } = props
    const { palette } = useTheme()

    const [isPending, startTransition] = useTransition();
    const { values, handleChange, setValues, errors, setFieldValue } = useFormik<LineDevisType>({
        initialValues: {
            _id: data?._id,
            title: data?.title ?? "",
            quantity: data?.quantity ?? 1,
            unitAmount: data?.unitAmount ?? 0,
            text: data?.text ?? "",
            totalAmount: data?.totalAmount ?? data?.quantity*data?.unitAmount ?? 0,
        },
        onSubmit: (values: LineDevisType) => {
        }
    })


    const updateProduct = (e: any, value: { title: string } | null, reason: any) => {
        if (value === null) return
        setFieldValue("title", value.title)
    }
    useEffect(() => {
        debounce(() => onUpdate(values._id, { ...values, totalAmount: values.quantity * values.unitAmount }), 500)()
    }, [values])

    return (
        <Card sx={{ backgroundColor: palette.bg_dark.main }}>
            <CardContent>
                <Stack gap={2}>
                    <TextField
                        type="text"
                        label="Nom du produit"
                        value={values.title}
                        disabled
                        //onChange={handleChange("title")}
                        style={{ backgroundColor: palette.bg_primary.main }}
                    />
                    <TextField
                        type="number"
                        label="Quantité"
                        value={values.quantity}
                        onChange={handleChange("quantity")}
                        style={{ backgroundColor: palette.bg_primary.main }}
                    />
                    <MonetaryNumberField
                        label="Prix unitaire"
                        value={values.unitAmount}
                        onChange={(value: any) => setFieldValue("unitAmount", value)}
                        style={{ backgroundColor: palette.bg_primary.main }}
                    />
                    <TextareaAutosize
                        aria-label="minimum height"
                        minRows={4}
                        value={values.text}
                        onChange={handleChange("text")}
                        placeholder=""
                        style={{
                            width: "100%",
                            backgroundColor: palette.bg_primary.main,
                            borderRadius: "0.5rem",
                            border: "none",
                            outline: "none",
                            color: palette.text_light.main,
                            padding: " 1rem",
                            boxSizing: "border-box"
                        }}
                    />
                </Stack>

            </CardContent>

            <CardActions disableSpacing sx={{ display: "flex", backgroundColor: palette.bg_primary.main }}>
                <IconButton onClick={onDelete} sx={{ padding: 0.5, margin: "auto" }}>
                    <DeleteIcon color={palette.grey[200]} />
                </IconButton>
            </CardActions>

        </Card>
    );
}
