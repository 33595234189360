import moment from "moment";
import { ProjectStatusType } from "../services_api/@types";
import { currentUserType } from "../state";

export function isImage(url: string) {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
}
export function getProjectStyles(status: ProjectStatusType, palette: any) {
  switch (status) {
    case "green":
      return { color: palette.success.main, bgColor: palette.tr_success.main };
    case "red":
      return { color: palette.error.main, bgColor: palette.tr_error.main };
    case "orange":
      return { color: palette.warning.main, bgColor: palette.tr_error.main };
    default:
      return { color: palette.gray.main };
  }
}
export function getProjectBorderSyle(status: ProjectStatusType, palette: any) {
  switch (status) {
    case "green":
      return { borderColor: palette.success.main };
    case "red":
      return { borderColor: palette.error.main };
    default:
      return { borderColor: palette.gray.main };
  }
}

export function listToMatrix(list: Array<any>, elementsPerSubArray: number) {
  let matrix: Array<Array<any>> = [],
    i,
    k;
  for (i = 0, k = -1; i < list.length; i++) {
    if (i % elementsPerSubArray === 0) {
      k++;
      matrix[k] = [];
    }
    matrix[k].push(list[i]);
  }
  return matrix;
}

export function matrixToList<T>(table?: T[][]): T[] {
  if (!table) return [];
  const arr: T[] = [];
  for (const row of table) {
    for (const item of row) arr.push(item);
  }
  return arr;
}

export const createFormData = (body: any) => {
  const data = new FormData();
  Object.keys(body).forEach((key) => {
    data.append(key, body[key]);
  });
  return data;
};

export function getUserFromStorage() {
  const user_str = localStorage.getItem("user");
  if (user_str === null) return null;
  return JSON.parse(user_str) as currentUserType;
}

export function isTheLastElement(arr: any[], index: number) {
  return index + 1 === arr.length;
}
export function removeEmpty(obj: any): any {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}
export function getAuthHeaders() {
  const user = getUserFromStorage() as currentUserType;
  return {
    Authorization: `Bearer ${user?.accessToken}`,
  };
}

export function extractErrorMessage(errorResponse: any): {
  status: string;
  detail: string;
} {
  const errorData = errorResponse.response?.data;
  const errorStatus: "400" | "404" | "301" | "500" =
    errorResponse.response?.status;
  const ERRORS_STATUS_MAP = {
    "400": "Bad Request",
    "404": "Not Found",
    "301": "Unautherized",
    "500": "Server Error",
  };
  let errMessage = "";
  if (errorData !== undefined) {
    errMessage = errorData.detail;
    if (!errMessage) {
      errMessage = Object.values(errorData).map((item: any) => {
        if (typeof item === "string") return item;
        if (item.length) return item[0];
      })[0];
    }
  }
  return { status: ERRORS_STATUS_MAP[errorStatus], detail: errMessage };
}

export const groupBy = <T>(
  array: T[],
  predicate: (value: T, index: number, array: T[]) => string
) =>
  array.reduce((acc, value, index, array) => {
    (acc[predicate(value, index, array)] ||= []).push(value);
    return acc;
  }, {} as { [key: string]: T[] });

export function getCalendar(date?: string) {
  if (!date) return "";
  return moment(date).calendar().split(" à")[0] || "";
}

export function getDateTimeString(date?: string) {
  if (!date) return "";
  return moment(date).format("DD MMMM [à] HH[h]mm");
}
export function getDateString(date?: string) {
  if (!date) return "";
  return moment(date).format("DD MMMM ");
}
export function getMapLink(
  street: string,
  city: string,
  postalCode: string
): string {
  let protocol = "https";
  if (
    navigator.platform.indexOf("iPhone") !== -1 ||
    navigator.platform.indexOf("iPod") !== -1 ||
    navigator.platform.indexOf("iPad") !== -1
  )
    protocol = "maps";
  const link = `${street}, ${postalCode} ${city}`;
  return `${protocol}://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${encodeURIComponent(
    link
  )}`;
}

export function openMail(email?: string) {
  if (!email) return;
  window.open(`mailto:${email}?subject=sujet&body=Bonjour`);
}

export function getUniqueId() {
  return Math.random().toString(16);
}
