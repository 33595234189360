import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'

export const SectionHeader = (props: { title: string }) => {
    const { palette } = useTheme()
    return (
        <Box sx={{ backgroundColor: palette.bg_dark.main, paddingX: 2, paddingY: 1, color: palette.text_light.main }}>
            <Typography variant="h3">
                {props.title}
            </Typography>
        </Box>
    )
}
