import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface Palette {
    tr_primary: Palette["primary"];
    gray: Palette["primary"];
    lightGrey: Palette["primary"];
    tr_warning: Palette["primary"];
    tr_success: Palette["primary"];
    tr_error: Palette["primary"];
    bg_primary: Palette["primary"];
    bg_dark: Palette["primary"];
    bg_dark_green: Palette["primary"];
    text_light: Palette["primary"];
    text_light2: Palette["primary"];
    muted_text: Palette["primary"];
  }
  interface PaletteOptions {
    tr_primary: PaletteOptions["primary"];
    gray: PaletteOptions["primary"];
    lightGrey: PaletteOptions["primary"];
    tr_warning: PaletteOptions["primary"];
    tr_success: PaletteOptions["primary"];
    tr_error: PaletteOptions["primary"];
    bg_primary: PaletteOptions["primary"];
    bg_dark: PaletteOptions["primary"];
    bg_dark_green: PaletteOptions["primary"];
    text_light: PaletteOptions["primary"];
    text_light2: PaletteOptions["primary"];
    muted_text: PaletteOptions["primary"];
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    poster: React.CSSProperties;
    P20_Lh180: React.CSSProperties;
    P20_Lh130: React.CSSProperties;
    P18_Lh180: React.CSSProperties;
    P18_Lh130: React.CSSProperties;
    P16_Lh180: React.CSSProperties;
    P16_Lh130: React.CSSProperties;
    P14_Lh180: React.CSSProperties;
    P14_Lh130: React.CSSProperties;
    P12_Lh180: React.CSSProperties;
    P12_Lh130: React.CSSProperties;
    P10_Lh130: React.CSSProperties;
    P8_Lh130: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    poster?: React.CSSProperties;
    P20_Lh180?: React.CSSProperties;
    P20_Lh130?: React.CSSProperties;
    P18_Lh180?: React.CSSProperties;
    P18_Lh130?: React.CSSProperties;
    P16_Lh180?: React.CSSProperties;
    P16_Lh130?: React.CSSProperties;
    P14_Lh180?: React.CSSProperties;
    P14_Lh130?: React.CSSProperties;
    P12_Lh180?: React.CSSProperties;
    P12_Lh130?: React.CSSProperties;
    P10_Lh130?: React.CSSProperties;
    P8_Lh130?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    P20_Lh180: true;
    P20_Lh130: true;
    P18_Lh180: true;
    P18_Lh130: true;
    P16_Lh180: true;
    P16_Lh130: true;
    P14_Lh180: true;
    P14_Lh130: true;
    P12_Lh180: true;
    P12_Lh130: true;
    P10_Lh130: true;
    P8_Lh130: true;
    subtitle1: false;
    subtitle2: false;
    body1: false;
    body2: false;
    caption: false;
    button: false;
    overline: false;
  }
}

const { palette } = createTheme();

export const Muitheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#7367f0",
    },
    secondary: {
      main: "#d0d2d6",
    },
    success: {
      main: "#1CF086",
    },
    warning: {
      main: "#f19843",
    },
    info: {
      main: "#04bcc4",
    },
    error: {
      main: "#ea5455",
    },

    tr_primary: palette.augmentColor({ color: { main: "#7367f030" } }),
    gray: palette.augmentColor({ color: { main: "#3b4253" } }),
    lightGrey: palette.augmentColor({ color: { main: "#d0d2d6" } }),
    tr_warning: palette.augmentColor({ color: { main: "#f1984330" } }),
    tr_success: palette.augmentColor({ color: { main: "#1CF08630" } }),
    tr_error: palette.augmentColor({ color: { main: "#ea545530" } }),
    bg_primary: palette.augmentColor({ color: { main: "#283046" } }),
    bg_dark: palette.augmentColor({ color: { main: "#161d31" } }),
    bg_dark_green: palette.augmentColor({ color: { main: "#1f374d" } }),
    text_light: palette.augmentColor({ color: { main: "#d0d2d6" } }),
    text_light2: palette.augmentColor({ color: { main: "#b4b7bd" } }),
    muted_text: palette.augmentColor({ color: { main: "#989CA5" } }),
  },
  typography: {
    h1: {
      fontSize: 22,
      fontWeight: 600,
      lineHeight: "28.6px",
    },
    h2: {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: "23.4px",
    },
    h3: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: "16.8px",
    },
    h4: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: "18.2px",
    },
    h5: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: "15.6px",
    },
    h6: {
      fontSize: 10,
      fontWeight: 600,
      lineHeight: "13.6px",
    },
    P20_Lh180: {
      fontSize: 20,
      lineHeight: "36px",
    },
    P20_Lh130: {
      fontSize: 20,
      lineHeight: "26px",
    },
    P18_Lh180: {
      fontSize: 18,
      lineHeight: "32.4px",
    },
    P18_Lh130: {
      fontSize: 18,
      lineHeight: "23.4px",
    },
    P16_Lh180: {
      fontSize: 16,
      lineHeight: "28.8px",
    },
    P16_Lh130: {
      fontSize: 16,
      lineHeight: "20.8px",
    },
    P14_Lh180: {
      fontSize: 14,
      lineHeight: "25.2px",
    },
    P14_Lh130: {
      fontSize: 14,
      lineHeight: "18.2px",
    },
    P12_Lh180: {
      fontSize: 12,
      lineHeight: "21.6px",
    },
    P12_Lh130: {
      fontSize: 12,
      lineHeight: "15.6px",
    },
    P10_Lh130: {
      fontSize: 10,
      lineHeight: "13px",
    },
    P8_Lh130: {
      fontSize: 8,
      lineHeight: "10px",
    },
  },
});
