import { TextField } from '@mui/material';
import React from 'react';
import { NumericFormat } from 'react-number-format';

export const NumberField = React.memo((props: any) => {
    const { value, onChange, decimalScale, internalScale = 0, suffix, ...rest } = props

    const formatInputValue = (value: any) => {
        console.log('formatInputValue', value);
        if (!value) {
            return '';
        }
        return (Number(value) / (10**internalScale)).toFixed(decimalScale).replace('.', ',') + suffix;
    };
  
    const parseInputValue = (floatValue: number | undefined) => {
        if(floatValue == null || isNaN(floatValue)) {
            return null;
        }
        return Number(Number(floatValue).toFixed(decimalScale-internalScale));
    };

    return (
        <NumericFormat
            customInput={TextField}
            decimalSeparator=","
            thousandSeparator=" "
            suffix={suffix}
            decimalScale={decimalScale}
            fixedDecimalScale
            value={formatInputValue(value)}
            onValueChange={(values) => {
                let internalValue = parseInputValue(values.floatValue);
                onChange(internalValue);
            }}
            {...rest}
        />
    )
}, (prevProps, nextProps) => { return prevProps.value === nextProps.value });

export const MonetaryNumberField = React.memo((props: any) => {
    const { value, onChange, ...rest } = props

    const decimalScale = 2;
    const internalScale = 2;

    return <NumberField value={value} onChange={onChange} decimalScale={decimalScale} internalScale={internalScale} suffix=" €" {...rest} />

}, (prevProps, nextProps) => { return prevProps.value === nextProps.value });

export const PercentageNumberField = React.memo((props: any) => {
    const { value, onChange, ...rest } = props

    const decimalScale = 0;
    const internalScale = 0;

    return <NumberField value={value} onChange={onChange} decimalScale={decimalScale} internalScale={internalScale} suffix=" %" {...rest} />

}, (prevProps, nextProps) => { return prevProps.value === nextProps.value });

/*export const MonetaryNumberField = React.memo((props: any) => {
    const { value, onChange, ...rest } = props

    const decimalScale = 2;

    const formatInputValue = (value: any) => {
        console.log('formatInputValue', value);
        if (!value) {
            return '';
        }
        return (value / 100).toFixed(decimalScale).replace('.', ',') + ' €';
    };
  
    const parseInputValue = (floatValue: number | undefined) => {
        console.log('parseInputValue', floatValue);
        if(floatValue == null || isNaN(floatValue)) {
            return null;
        }
        return Number(Number(floatValue).toFixed(decimalScale-2));
    };

    return (
        <NumericFormat
            customInput={TextField}
            decimalSeparator=","
            thousandSeparator=" "
            suffix=" €"
            decimalScale={decimalScale}
            fixedDecimalScale
            value={formatInputValue(value)}
            onValueChange={(values) => {
                let internalValue = parseInputValue(values.floatValue);
                onChange(internalValue);
            }}
            {...rest}
        />
    )
}, (prevProps, nextProps) => { return prevProps.value === nextProps.value });



export const PercentageNumberField = React.memo((props: any) => {
    const { value, onChange, ...rest } = props

    const decimalScale = 0;

    const formatInputValue = (value: any) => {
        console.log('formatInputValue', value);
        if (!value) {
            return '';
        }
        return Number(value).toFixed(decimalScale).replace('.', ',') + ' %';
    };
  
    const parseInputValue = (floatValue: number | undefined) => {
        console.log('parseInputValue', floatValue);
        if(floatValue == null || isNaN(floatValue)) {
            return null;
        }
        return Number(Number(floatValue).toFixed(decimalScale));
    };

    return (
        <NumericFormat
            customInput={TextField}
            decimalSeparator=","
            thousandSeparator=" "
            suffix=" %"
            decimalScale={decimalScale}
            fixedDecimalScale
            value={formatInputValue(value)}
            onValueChange={(values) => {
                let internalValue = parseInputValue(values.floatValue);
                onChange(internalValue);
            }}
            {...rest}
        />
    )
}, (prevProps, nextProps) => { return prevProps.value === nextProps.value });
*/