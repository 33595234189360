import { AxiosInstance } from "axios";

interface SendPaymentRequestPayload {
    projetId: number;
    deviId: number;
    totalAmount: number;
    description?: string;
}

export async function submitSendPaymentRequest(payload: SendPaymentRequestPayload, axiosInstance: AxiosInstance) {
    const response = await axiosInstance.post('/paymentRequests', payload);
    return response.data;
}
