import { Button, Divider, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'
import MyDrawer from '../navigation/MyDrawer'

type ScreenHeaderProps = {
    title: string
}
export const ScreenHeader = (props: ScreenHeaderProps) => {
    const { title } = props
    const { palette } = useTheme()
    return (
        <Stack direction="row" gap={2} sx={{ padding: 1, color: palette.text_light.main }} alignItems="center" >
            <MyDrawer />
            <Divider
                sx={{ backgroundColor: 'gray.main' }}
                orientation="vertical"
                variant="middle"
                flexItem
            />
            <Typography variant="h1" sx={{ flexGrow: 1, textAlign: "center", paddingRight: 5 }} >
                {title}
            </Typography>
        </Stack>
    )
}
