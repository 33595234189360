import React, { FC, useState } from 'react'
import { Box, Button, Divider, IconButton, Slide, Stack, SwipeableDrawer, Typography, useTheme } from '@mui/material'

import { ArrowDownIcon, ArrowUpIcon } from '../../../../../../icons'
import { BorderBottom } from '@mui/icons-material'

type ModalWraperProps = {
    isOpen: boolean,
    onSave?: () => void
    onCancel?: () => void
    title: string,
    saveBtnText?: string
    cancelBtnText?: string
    children?: React.ReactNode
    disableSave?: boolean
    padding?: number
}
export const ModalWraper: FC<ModalWraperProps> = (props) => {
    const {
        isOpen,
        onSave = () => { },
        onCancel = () => { },
        title, saveBtnText = "Enregister",
        cancelBtnText = "Annuler",
        children,
        disableSave = false,
        padding = 2,
    } = props

    const { palette } = useTheme()

    const [fullScreen, setFullScreen] = useState(false)

    return (

        <Slide direction="up" timeout={400} in={isOpen}>

            <Stack
                position="fixed"
                height="100%"
                width="100%"
                zIndex={100}
                top={0}
                left={0}
                sx={{ backgroundColor: fullScreen ? palette.bg_primary.main : "transparent", }}
                justifyContent="flex-end"

            >
                <div
                    onClick={onCancel}
                    style={{
                        backgroundColor: "#00000020",
                        height: fullScreen ? 0 : "20%",
                        width: "100%",
                    }}
                />


                <Box sx={{
                    backgroundColor: palette.bg_dark.main,
                    top: 0,
                    width: "100%",
                    height: fullScreen ? "100%" : "80%",
                    maxHeight: "100%",
                    borderTopLeftRadius: "2rem",
                    borderTopRightRadius: "2rem",
                    color: palette.text_light.main,
                    overflowY: "auto",
                }}>
                    <Stack >

                        <Stack
                            sx={{
                                position: "sticky",
                                top: 0,
                                zIndex: 100,
                                paddingX: 2,
                                paddingY: 1,
                                backgroundColor: palette.bg_dark.main,
                                borderBottom: `0.5px solid ${palette.gray.main}`
                            }}

                            direction="row"

                            alignItems="center"

                        >
                            <Button onClick={onCancel} sx={{ textTransform: "none" }}>
                                <Typography variant="P14_Lh130" style={{ color: palette.success.main }}>
                                    {cancelBtnText}
                                </Typography>
                            </Button>
                            <div style={{ cursor: "pointer", flexGrow: 1 }} onClick={() => setFullScreen(prev => !prev)} >
                                <Stack gap={0.5} alignItems="center">
                                    <Typography variant="h4" sx={{ textAlign: "center" }}>
                                        {title}
                                    </Typography>
                                </Stack>
                            </div>
                            {
                                <Button onClick={onSave} disabled={disableSave} sx={{ textTransform: "none" }} >
                                    <Typography variant="P14_Lh130" sx={{ color: disableSave ? palette.muted_text.main : palette.success.main }}>
                                        {saveBtnText}
                                    </Typography>
                                </Button>
                            }

                        </Stack>

                        <Stack padding={padding}>
                            {children}
                        </Stack>

                    </Stack>

                </Box>
            </Stack>

        </Slide>

    )
}
