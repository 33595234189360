import { Box, Button, ButtonGroup, CircularProgress, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { MERDIA_SERVER_BASE_URL } from '../../consts';
import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf"
import throttle from 'lodash/throttle';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { LoadingBlock } from './LoadingBlock';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
type PdfViewerProips = {
    uri?: string
}

export function PdfViewer(props: PdfViewerProips) {
    const { palette } = useTheme()
    const path = `${MERDIA_SERVER_BASE_URL}/${props.uri}`
    pdfjs.GlobalWorkerOptions.workerSrc =
        `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const [numPages, setNumPages] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page
    const [deviceWidth, setdeviceWidth] = useState(window.innerWidth)
    function onDocumentLoadSuccess({ numPages }: any) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset: number) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    useEffect(() => {
        const listner = throttle(() => setdeviceWidth(window.innerWidth), 400)
        window.addEventListener("resize", listner)
        /** remove right click */
        document.addEventListener("contextmenu", (event) => {
            event.preventDefault();
        });
        return () => {
            window.removeEventListener("resize", listner)
            document.removeEventListener("contextmenu", (event) => {
                event.preventDefault();
            })
        }
    }, [])
    const onDownloadClick = (e: any) => {
        // using Java Script method to get PDF file
        fetch(path).then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'Devis.pdf';
                alink.click();
            })
        })
    }
    return (
        <Stack position="relative" gap={2}>
            <Document
                file={path}
                onLoadSuccess={onDocumentLoadSuccess}
                renderMode='canvas'
            >
                <Page
                    width={deviceWidth}
                    height={200}
                    pageNumber={pageNumber}
                    loading={<LoadingBlock />}
                />
            </Document>
            <Stack
                bgcolor={palette.tr_primary.main}
                sx={{ borderRadius: 2, px: 1, position: "absolute", bottom: 6, right: 6, }}
                direction={"row"} gap={2} alignItems="center" >
                <Typography variant="P12_Lh130" >
                    Page {pageNumber || (numPages ? 1 : "--")} / {numPages || "--"}
                </Typography>
                <ButtonGroup
                    disableElevation
                    variant="text"
                    aria-label="Disabled elevation buttons"
                    size='small'
                >
                    <Button onClick={previousPage}>
                        <KeyboardArrowLeftRoundedIcon />
                    </Button>
                    <Button onClick={nextPage}>
                        <KeyboardArrowRightRoundedIcon />
                    </Button>
                </ButtonGroup>
                <Tooltip title="Télécharger" >
                    <IconButton
                        sx={{ color: "#542" }}
                        onClick={onDownloadClick}
                    >
                        <FileDownloadRoundedIcon color="inherit" />
                    </IconButton>
                </Tooltip>

            </Stack>


        </Stack>
    );
}


