import { Stack, Typography, useTheme } from '@mui/material'
import React from 'react'

type RealiseParBlockProps = {
    user?: any
}
export const RealiseParBlock = (props: RealiseParBlockProps) => {
    const { palette } = useTheme()
    if (!props.user) return <></>
    return (
        <Stack direction="row" alignItems="center" gap={1} marginTop={3}>
            <Typography variant="P10_Lh130" sx={{ color: palette.gray.main }}>
                Réalisé par
            </Typography>
            <Typography variant="P12_Lh130" sx={{ color: 'muted_text.main' }}>
                {props.user?.firstName} {props.user?.lastName}
            </Typography>
        </Stack>
    )
}
