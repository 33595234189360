import { Box, Stack ,useTheme} from '@mui/material'
import React from 'react'
import { ScreenHeader } from '../../util'
import { BarChart, ChiffreAffaire, EtudeProjets, OrderRecieved } from './components'

export const SuiviCommercial = () => {
    const { palette } = useTheme()
    return (
        <Stack sx={{ backgroundColor: palette.bg_primary.main,  minHeight: "100vh" }}>
            <ScreenHeader title='Commercial' />
            <Stack flexGrow={1} gap={8} paddingX={4} paddingY={8} sx={{ backgroundColor: palette.bg_dark_green.main }}>
                <ChiffreAffaire />
                <EtudeProjets />
                <OrderRecieved />
                <BarChart />
            </Stack>
        </Stack>
    )
}
