
import React from 'react'
import { MyModalProps } from './@types'
import { ModalWraper } from './ModalWraper'
import { CreatePaymentRequest, Devi, EnvoiePaymentType } from '../../../../../../../services_api'
import { useFormik } from 'formik'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { devisKeys, projectsKeys } from '../../../../../../../services_api/keys'
import { EnvoiePaymentService, useDevis } from '../../../../../../../services_api/services'
import { LoadingLayer } from '../../../../../../util'
import { Box, Button, Collapse, Divider, Fade, FormControl, FormControlLabel, FormLabel, IconButton, InputAdornment, MenuItem, Radio, RadioGroup, Stack, Switch, TextareaAutosize, TextField, Typography, useTheme } from '@mui/material'
import { RealiseParBlock } from '../../../RealiseParBlock'

import * as Yup from 'yup'
import { number } from 'yup/lib/locale'
import { getDateString } from '../../../../../../../helpers'

export const EnvoiePaymentShema = Yup.object().shape({
  projetId: Yup.number().required("champ obligatoire"),
  deviId: Yup.number().required('champ obligatoire'),
  firstInstallmentRate: Yup
    .number()
    .required('champ obligatoire')
    .min(1, " ça doit être positif")
    .max(100, "maximum c's 100%")
})

type EnvoiePaymentModalProps = MyModalProps & { data?: EnvoiePaymentType, devis: Devi[] }


export const EnvoiePayment = (props: EnvoiePaymentModalProps) => {
  const {
    action,
    isOpen = false,
    data,
    devis: les_devis,
    readonly,
    projetId,
    onclose = () => { },
  } = props

  const { palette } = useTheme()


  const { values, errors, touched, isValid, handleChange, handleSubmit, setFieldValue, handleBlur } =
    useFormik<CreatePaymentRequest>({
      initialValues: {
        projetId: projetId,
        deviId: data?.deviId ?? (les_devis.length > 0 ? les_devis[0].id : ("" as any)),
        paymentMode: data?.paymentMode ?? "lien",
        isPaid: data?.isPaid ?? false,
        numberOfInstallments: data?.numberOfInstallments ?? 1 as any,
        firstInstallmentRate: data?.firstInstallmentRate ?? 100 as any,
      },
      validationSchema: EnvoiePaymentShema,
      initialErrors: { deviId: "required" },
      onSubmit: (values) => {
        switch (action) {
          case "create":
            create({
              ...values,
              isPaid: values.paymentMode === "comptant",

            });
            break;
          case "update":
            if (data?.id) update({ id: data.id, isPaid: values.isPaid });
            break;
          default: break
        }
      }
    })

  const queryClient = useQueryClient()
  const refreshProject = () => {
    queryClient.invalidateQueries([projectsKeys.for_many, projetId])
  }

  const { mutate: create, isLoading: isCreating } = useMutation(EnvoiePaymentService.createOne, {
    onSuccess: (data) => {
      refreshProject()
      onclose()
    },
    onError: (err: any) => {
    },
  })

  const { mutate: update, isLoading: isUpdating } = useMutation(EnvoiePaymentService.updateOne, {
    onSuccess: (data) => {
      refreshProject()
      onclose()
    },
    onError: (err: any) => {
    },
  })

  if (!isOpen) return <></>
  return (
    <ModalWraper
      title="Création de facture"
      saveBtnText='Envoyer'
      cancelBtnText={readonly ? "Fermer" : "Annuler"}
      onSave={handleSubmit}
      onCancel={onclose}
      isOpen={isOpen}
      disableSave={readonly || !isValid}
    >
      <LoadingLayer open={isCreating || isUpdating} />
      <Stack paddingBottom={5} gap={3}>
        {/*<FormControl >
          <Stack direction="row" alignItems="center" gap={2}>
            Payment
            <RadioGroup
              aria-labelledby="rdv-radio-buttons-group-label"
              defaultValue="installation"
              name="radio-buttons-group"
              sx={{ width: "100%", dieplay: "flex", flexDirection: "row", justifyContent: "space-between" }}
              value={values.paymentMode}
              onChange={handleChange("paymentMode")}
            >
              <FormControlLabel disabled={readonly} value="lien" control={<Radio color="warning" />} label="En attente" />
              <FormControlLabel disabled={readonly} value="comptant" control={<Radio color="success" />} label="Effectué" />
            </RadioGroup>
          </Stack>
        </FormControl>
        <FormControl >
          <Collapse in={values.paymentMode === "lien"}  >
            <Stack direction="row" alignItems="center" gap={2}>
              <RadioGroup
                aria-labelledby="rdv-radio-buttons-group-label"
                defaultValue="installation"
                name="radio-buttons-group"
                sx={{ width: "100%", dieplay: "flex", flexDirection: "row", justifyContent: "space-between" }}
                value={values.numberOfInstallments}
                onChange={handleChange("numberOfInstallments")}
              >

                {[1, 2, 3, 4].map(e => (
                  <FormControlLabel key={e} disabled={readonly} value={e} control={<Radio color="default" />} label={`${e} fois`} />
                ))}
              </RadioGroup>
            </Stack>
          </Collapse>

        </FormControl>*/}

        <TextField
          id="outlined-select-currency"
          select
          label="Sélectionner un devis"
          value={values.deviId}
          onChange={handleChange("deviId")}
          onBlur={handleBlur('deviId')}
          error={touched.deviId && Boolean(errors.deviId)}
          helperText={touched.deviId ? errors.deviId : ""}
          disabled={readonly}
        >
          {les_devis.map((option) => (
            <MenuItem key={option.id} value={option.id}  >
              {`Devis du ${getDateString(option.createdAt)} - ${option.totalAmountString}`}
            </MenuItem>
          ))}
        </TextField>
        <Collapse in={values.paymentMode === "lien"}>
          <TextField
            fullWidth
            id="firstInstallmentRate"
            type="number"
            label="Montant sur le devis à payer tout de suite"
            value={values.firstInstallmentRate}
            onChange={handleChange("firstInstallmentRate")}
            onBlur={handleBlur('firstInstallmentRate')}
            error={touched.firstInstallmentRate && Boolean(errors.firstInstallmentRate)}
            helperText={touched.firstInstallmentRate ? errors.firstInstallmentRate : ""}
            disabled={readonly}
            InputProps={{
              startAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
            style={{ backgroundColor: 'bg_primary.main' }}
          />
        </Collapse>


        <RealiseParBlock user={data?.installateurUser} />

      </Stack>
    </ModalWraper>
  )
}
