import { useQuery } from "@tanstack/react-query";
import { useInfinitFetche } from "../../hooks";
import { IndexResult, Projet } from "../@types";
import { projectsKeys } from "../keys";
import { GenricService } from "./geniric.service";

export class ProjectService {
  static async fetchInfinit(params: any): Promise<IndexResult<Projet>> {
    const route = `/techniciensApi/v1/projets`;
    return GenricService.fetchInfinit<Projet>(route, params);
  }

  static async fetchAll(): Promise<Projet[] | undefined> {
    const route = `/techniciensApi/v1/projets`;
    return GenricService.fetch<Projet[]>(route, {});
  }

  static async fetchOne(id: number): Promise<Projet | undefined> {
    const route = `/techniciensApi/v1/projets`;
    return GenricService.fetchOneById<Projet>(route, id);
  }
}

export function useInfinitProjects() {
  const PAGESIZE = 6;
  return useInfinitFetche<Projet>(
    projectsKeys.for_many,
    "/techniciensApi/v1/projets",
    {},
    PAGESIZE
  );
}

export const useProject = (id: number) => {
  return useQuery(
    [projectsKeys.for_many, id],
    () => ProjectService.fetchOne(id),
    {
      retry: false,
    }
  );
};
