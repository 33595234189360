import { ModalWraper } from './ModalWraper'
import { Divider, Stack, TextareaAutosize, Typography, useTheme, Box } from '@mui/material'
import { ImagesPicker } from '../../../ImagesPicker'
import { MyModalProps } from './@types'
import { useFormik } from 'formik'
import { CreateCompteRenduRequest, InterventionCompteRendu } from '../../../../../../../services_api/@types'
import { RealiseParBlock } from '../../../RealiseParBlock'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { projectsKeys } from '../../../../../../../services_api/keys'
import { CompteRenduInvService } from '../../../../../../../services_api/services'
import { LoadingLayer } from '../../../../../../util'


type CompteRenduInterventionModalProps = MyModalProps & { data?: InterventionCompteRendu }

export const CompteRenduInterventionModal = (props: CompteRenduInterventionModalProps) => {
  const {
    onclose = () => { },
    isOpen = false,
    data,
    readonly = false,
    projetId,
    action
  } = props

  const { palette } = useTheme()

  const queryClient = useQueryClient()
  const refreshProject = () => {
    queryClient.invalidateQueries([projectsKeys.for_many, projetId])
  }

  const { values, handleChange, handleSubmit, errors, setFieldValue } = useFormik<CreateCompteRenduRequest>({
    initialValues: {
      projetId: projetId,
      text: data?.text || "",
      attachedFiles: data?.attachedFiles || [],
    },
    onSubmit: (values) => {
      switch (action) {
        case "create":
          create(values); break;
        case "update":
          if (data?.id) update({ ...values, id: data.id });
          break;
        default: break
      }
    }
  })
  const { mutate: create, isLoading: isCreating } = useMutation(CompteRenduInvService.createOne, {
    onSuccess: (data) => {
      refreshProject()
      onclose()
    },
    onError: (err: any) => {
    },
  })

  const { mutate: update, isLoading: isUpdating } = useMutation(CompteRenduInvService.updateOne, {
    onSuccess: (data) => {
      refreshProject()
      onclose()
    },
    onError: (err: any) => {
    },
  })

  if (!isOpen) return <></>
  return (
    <ModalWraper
      title="Compte rendu intervention"
      onSave={handleSubmit}
      cancelBtnText={readonly ? "Fermer" : "Annuler"}
      saveBtnText='Valider'
      onCancel={onclose} isOpen={isOpen}
      disableSave={readonly}
      padding={0}
    >
      <LoadingLayer open={isCreating || isUpdating} />
      <Stack paddingBottom={5}>
        <Box sx={{ position: "relative", paddingY: 1.5, paddingX: 2, borderBottom: `0.5px solid ${palette.gray.main}` }}>
          <TextareaAutosize
            aria-label="minimum height"
            minRows={7}
            value={values.text}
            onChange={handleChange("text")}
            readOnly={readonly}
            placeholder=""
            style={{
              width: "100%",
              backgroundColor: palette.bg_primary.main,
              borderRadius: "0.5rem",
              border: "none",
              outline: "none",
              color: 'text_light.main',
              padding: " 1rem",
              boxSizing: "border-box"
            }}
          />

        </Box>
        <Stack padding={2} gap={0.5}>
          <Typography variant="P14_Lh130" sx={{ color: 'text_light.main' }}>
            Pieces jointes
          </Typography>
          <ImagesPicker
            attachedFiles={values?.attachedFiles}
            updateParentData={setFieldValue}
          />
          <RealiseParBlock user={data?.installateurUser} />
        </Stack>

      </Stack>
    </ModalWraper >
  )
}
