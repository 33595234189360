import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { Box, Typography, useTheme } from '@mui/material'
import "./my-calendar.css"
import { EventType } from '../Agenda'
import 'moment/locale/fr';
import { P } from '@antv/g2plot'
import { EventItem } from './Event'

const localizer = momentLocalizer(moment)









type MyCalendarProps = {
  events?: EventType[]
}

export const MyCalendar = (props: MyCalendarProps) => {
  const { events = [] } = props
  const { palette } = useTheme()


  return (
    <Box sx={{ color: palette.text_light.main, paddingY: 2 }}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 700 }}
        views={["month", "week", "day"]}
        defaultView="week"
        {...getWorkTime()}

        components={{
          event: EventItem as any,
        }}
        messages={{
          next: ">",
          previous: "<",
          today: "Auj",
          month: "Mois",
          week: "Sem",
          day: "Jour",
          work_week: "Sem"
        }}
      />
    </Box>
  )
}

function getWorkTime() {
  const today = new Date();
  return {
    min: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      7
    ),
    max:
      new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        22
      )
  }
}
