import { Box, Button, Divider, Skeleton, Stack, Typography, useTheme } from '@mui/material'
import React, { Fragment } from 'react'
import { Outlet } from 'react-router-dom'
import { MyChip, MyProgressBar, ScreenHeader } from '../../util'
import { ProjectItem } from './components'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { ProjectService, useInfinitProjects } from '../../../services_api/services'
import moment from 'moment'
import { LoadingBlock } from '../../util'
import { GenricService } from '../../../services_api/services/geniric.service';
import { matrixToList } from '../../../helpers';
import { IndexResult, Projet } from '../../../services_api/@types'
import { useInfinitFetche } from '../../../hooks'
import { projectsKeys } from '../../../services_api/keys'



export const Projets = () => {
    const { palette } = useTheme()
    const {
        results: projects,
        isLoading,
        isFetchingNextPage: loading_more,
        fetchNextPage: loadMore,
        hasNextPage: can_load_more,
    } = useInfinitProjects()
    return (
        <Stack sx={{ backgroundColor: palette.bg_primary.main, minHeight: "100vh" }}>
            <ScreenHeader title='Projets' />
            <Stack flexGrow={1} sx={{ backgroundColor: palette.bg_dark_green.main }} paddingTop={3}>
                {isLoading &&
                    <LoadingBlock />
                }

                {projects &&
                    projects.map((project, index) => (
                        <Fragment key={index}>
                            <Divider sx={{ backgroundColor: palette.grey[500], height: "0.5px" }} />
                            <ProjectItem
                                projectId={project.id}
                                idString={project.idString}
                                date={moment(project.createdAt).format("DD MMMM")}
                                statusColor={project.statusColor}
                                agentName={`${project.customer?.firstName} ${project.customer?.lastName}`}
                                adresse={`${project.addressPostalCode} - ${project.addressCity}`}
                                agentType="Particulier"
                                task={project.statusText}
                                progress={project.progression * 100}
                            />
                        </Fragment>

                    ))
                }
                <Divider sx={{ backgroundColor: palette.grey[500], height: "0.5px" }} />
                {loading_more ?
                    <LoadingBlock />
                    :
                    can_load_more && <Button
                        onClick={() => loadMore()}
                        sx={{ marginX: "auto", textTransform: "none", maxWidth: 200, marginY: 3 }}
                    >
                        <Typography variant="P16_Lh130" sx={{ color: palette.info.main, margin: "auto", textAlign: "center" }} >
                            Voir Plus
                        </Typography>
                    </Button>
                }
            </Stack>


        </Stack>
    )
}





