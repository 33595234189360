import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  CreateDevisRequest,
  CreateDevisResponse,
  Devi,
  IndexResult,
  UpdateDevisRequest,
  UpdateDevisResponse,
} from "../@types";
import { devisKeys } from "../keys";

import { GenricService } from "./geniric.service";

const BASE_ROUT = `/techniciensApi/v1/devis`;

export class DevisService {
  static async fetch(projetId: number): Promise<IndexResult<Devi>> {
    const route = BASE_ROUT;
    return GenricService.fetch<IndexResult<Devi>>(route, { projetId });
  }

  static async fetchOne(id: number): Promise<Devi> {
    const route = BASE_ROUT;
    return GenricService.fetchOneById<Devi>(route, id);
  }

  static async createOne(
    data: CreateDevisRequest
  ): Promise<CreateDevisResponse> {
    const route = BASE_ROUT;
    return GenricService.PostOne<CreateDevisRequest, CreateDevisResponse>(
      route,
      data
    );
  }

  static async updateOne(
    data: UpdateDevisRequest
  ): Promise<UpdateDevisResponse> {
    console.log("===============================");

    const { id, ...body } = data;
    const route = `${BASE_ROUT}/${data.id}`;
    return GenricService.PatchOne<
      Omit<UpdateDevisRequest, "id">,
      UpdateDevisResponse
    >(route, body);
  }

  static async send(id: number): Promise<void> {
    const route = `${BASE_ROUT}/${id}/send`;
    return GenricService.PostOne(route, {});
  }
}

export const useDevis = (projetId: number) => {
  return useQuery(
    [devisKeys.for_many, projetId],
    () => DevisService.fetch(projetId),
    {
      retry: false,
    }
  );
};

export function useCreateDevis(onCreated: () => void) {
  return useMutation(DevisService.createOne, {
    onSuccess: (data) => {
      onCreated();
    },
    onError: (err: any) => {},
  });
}
export function useUpdateDevis(onUpdated: () => void) {
  return useMutation(DevisService.updateOne, {
    onSuccess: (data) => {
      onUpdated();
    },
    onError: (err: any) => {},
  });
}
