import { InputNumber, InputNumberProps } from "antd";
import _ from "lodash";


export const AmountInput = (props: InputNumberProps) => {
    const { ...rest } = props;    
    return (
        <InputNumber
            addonAfter="€"
            min={0.0}
            formatter={(value, info) => {
                if(info.userTyping) {
                    return info.input;
                } else {
                    return (_.toSafeInteger(value)/100).toFixed(2);
                }
            }}
            parser={(displayValue) => {
                return displayValue ? Math.max(0, Math.floor(100*parseFloat(displayValue))) : 0
            }}
            {...rest}
        />
    )
}