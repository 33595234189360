import { Box, IconButton, Stack, useTheme } from '@mui/material'
import { useFormik } from 'formik'
import React, { Fragment } from 'react'
import { MERDIA_SERVER_BASE_URL } from '../../../../consts'
import { isImage } from '../../../../helpers'
import { FileDescription } from '../../../../services_api/@types'
import { useUploadMany } from '../../../../services_api/services'
import { CameraIcon, CircleCloseIcon } from '../../../icons'
import { LoadingLayer } from '../../../util'
import { ImagePopUp } from './ShowImagePopUp'



type ImagesPickerProps = {
    attachedFiles?: FileDescription[] | null,
    readOnly?: boolean,
    updateParentData?: (key: string, value: any) => void
}
export const ImagesPicker = (props: ImagesPickerProps) => {
    const { attachedFiles, readOnly = false, updateParentData } = props
    const { palette } = useTheme()

    const { values, setFieldValue } = useFormik({
        initialValues: {
            attachedFiles: attachedFiles ?? []
        },
        onSubmit: (values) => { }
    })


    const handleCapture = async (e: any) => {
        const files = e.target.files
        if (files) {
            if (files.length !== 0) {
                uploadMany(Array.from(files))
            }
        }
    };
    const deleteImage = (uri: string) => {
        return () => setFieldValue("attachedFiles", values.attachedFiles.filter(f => f.uri !== uri))
    }

    React.useEffect(() => {
        updateParentData && updateParentData("attachedFiles", values.attachedFiles)
    }, [values.attachedFiles.length])

    const { mutate: uploadMany, isLoading: uploading } = useUploadMany(
        (files: FileDescription[]) => {
            setFieldValue("attachedFiles", [...values.attachedFiles, ...files]);
        })


    return (
        <Stack direction="row" flexWrap="wrap" gap={1.5} paddingY={1} >
            <LoadingLayer open={uploading} />
            {([...values.attachedFiles, false]).map((image, index) => (
                <Fragment key={index}>
                    {
                        typeof image === "object" ?
                            <ImagePopUp
                                image={`${MERDIA_SERVER_BASE_URL}/${image.uri}`}
                                remover={readOnly ? <></> :
                                    <IconButton
                                        onClick={deleteImage(image.uri)}
                                        sx={{
                                            padding: 0,
                                            color: "gray.main",
                                            "&:hover": { color: "error.main", },
                                            position: "absolute",
                                            top: -10,
                                            right: -10,
                                            zIndex: 100
                                        }}
                                    >
                                        <CircleCloseIcon size='1.2rem' color="inherit" />
                                    </IconButton>
                                }
                            />

                            :
                            <>
                                {!readOnly &&
                                    <PickerItemWraper>
                                        <IconButton sx={{ margin: "auto" }} color="inherit" aria-label="upload picture" component="label">
                                            <input hidden
                                                accept="image/*"
                                                id="icon-button-file"
                                                type="file"
                                                onChange={handleCapture}
                                                multiple={true}
                                            />
                                            <CameraIcon color={palette.muted_text.main} />
                                        </IconButton>
                                    </PickerItemWraper>}
                            </>


                    }
                </Fragment>
            ))}

        </Stack>
    )
}


export const PickerItemWraper = ({ children, onClick }: any) => {
    const { palette } = useTheme()
    return (
        <div onClick={onClick}>
            <Box

                sx={{
                    display: "flex",
                    position: "relative",
                    border: "2px solid",
                    borderColor: palette.gray.main,
                    width: 48,
                    height: 72,
                    borderRadius: 2.5,
                    overflow: 'hidden'
                }}
            >
                {children}
            </Box>
        </div>

    )
}
