import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled, SxProps, Theme } from '@mui/material/styles';
import { Stack ,useTheme} from '@mui/material';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Stack
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            flexGrow={value === index ? 1 : 0}
        >
            {value === index && (
                <>{children}</>
            )}
        </Stack>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

type TabulationProps = {
    tabs: React.ReactNode[]
    panels: React.ReactNode[]
    firstTabHidden?: boolean
    disableIndicator?: boolean
}

export function Tabulation(props: TabulationProps) {
    const { tabs, panels, firstTabHidden = false, disableIndicator = false } = props
    const [value, setValue] = React.useState(0);
    const { palette } = useTheme()
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    
    const AntTabs = styled(Tabs)({
        borderBottom: `none`,
        '& .MuiTabs-indicator': {
            backgroundColor: palette.text_light.main,
            height: disableIndicator ? 0 : 2
        },
    });

    return (
        <Stack sx={{ width: '100%' }} flexGrow={1}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <AntTabs
                    textColor="inherit"
                    sx={{ color: palette.text_light.main }}
                    value={value} onChange={handleChange}
                    variant="scrollable"
                    scrollButtons={false}
                    aria-label="basic tabs example"
                >
                    {tabs.map((tab, index) => (
                        <Tab key={index} label={tab} {...a11yProps(index)} sx={(index > 0 || !firstTabHidden) ? {} : { display: "none" }} />
                    ))}
                </AntTabs>
            </Box>
            <Stack flexGrow={1}>
                {
                    panels.map((panel, index) => (
                        <TabPanel key={index} value={value} index={index}>
                            {panel}
                        </TabPanel>
                    ))
                }
            </Stack>

        </Stack>
    );
}