import { Stack } from '@mui/material'
import React from 'react'
import { ScreenHeader } from '../../util'

export const Devis = () => {
    return (
        <Stack sx={{backgroundColor:"#283046",  minHeight: "100vh"}}>
            <ScreenHeader title='Devis' />
        </Stack>
    )
}
