import React from 'react'
import { ModalWraper } from './ModalWraper'
import { Box, Button, Collapse, Divider, FormControl, FormControlLabel, FormLabel, IconButton, MenuItem, Radio, RadioGroup, Stack, Switch, TextareaAutosize, TextField, Typography, useTheme } from '@mui/material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider, MobileDatePicker, MobileDateTimePicker, MobileTimePicker } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import { MyModalProps } from './@types';
import { useQuery, useQueryClient } from '@tanstack/react-query'


import moment from 'moment';
import { CreateInterventionRdvRequest, InterventionRdv } from '../../../../../../../services_api';
import { RealiseParBlock } from '../../../RealiseParBlock';
import { useMutation } from '@tanstack/react-query';
import { InterventionService } from '../../../../../../../services_api/services';
import toast from 'react-hot-toast';
import { LoadingLayer } from '../../../../../../util';
import { projectsKeys } from '../../../../../../../services_api/keys';
import { removeEmpty } from '../../../../../../../helpers';

// Get QueryClient from the context



type CreationRDVModalProps = MyModalProps & { data?: InterventionRdv }
export const CreationRDVModal = (props: CreationRDVModalProps) => {
  const {
    action,
    isOpen = false,
    data,
    readonly,
    projetId,
    onclose = () => { },
  } = props

  const { palette } = useTheme()

  const [will_report, setWillReport] = React.useState(false)
  const handleReport = () => {
    setFieldValue("etat", "none")
    setWillReport(true)
  }
  const queryClient = useQueryClient()
  const refreshProject = () => {
    queryClient.invalidateQueries([projectsKeys.for_many, projetId])
  }


  const { values, handleChange, handleBlur, handleSubmit, errors, setFieldValue, touched } =
    useFormik<CreateInterventionRdvRequest & {
      etat: "termine" | "annule" | "none",
      new_scheduledStartDatetime: Date | null,
      new_scheduledEndDatetime: Date | null
    }>({
      initialValues: {
        projetId: projetId,
        text: data?.text || "",
        interventionType: data?.interventionType ?? "devis",
        scheduledStartDatetime: data?.scheduledStartDatetime ? moment(data?.scheduledStartDatetime).toDate() : null,
        scheduledEndDatetime: data?.scheduledEndDatetime ? moment(data?.scheduledEndDatetime).toDate() : null,
        isDone: data?.isDone ?? false,
        isCancelled: data?.isCancelled ?? false,
        etat: data?.isCancelled ? "annule" : data?.isDone ? "termine" : "none",
        new_scheduledStartDatetime: null,
        new_scheduledEndDatetime: null,
      },
      onSubmit: (values) => {
        switch (action) {
          case "create":
            const { etat, new_scheduledStartDatetime, new_scheduledEndDatetime, ...body } = values
            create({
              ...body,
              isCancelled: etat === "annule",
              isDone: etat === "termine"
            });
            break;
          case "update":
            if (data?.id) update(removeEmpty({
              id: data.id,
              text: values.text,
              isCancelled: values.etat === "annule",
              isDone: values.etat === "termine",
              scheduledStartDatetime: values.new_scheduledStartDatetime,
              scheduledEndDatetime: values.new_scheduledEndDatetime,
            }));
            break;
          default: break
        }
      }
    })

  const { mutate: create, isLoading: isCreating } = useMutation(InterventionService.createOne, {
    onSuccess: (data) => {
      refreshProject()
      onclose()
    },
    onError: (err: any) => {
    },
  })

  const { mutate: update, isLoading: isUpdating } = useMutation(InterventionService.updateOne, {
    onSuccess: (data) => {
      refreshProject()
      onclose()
    },
    onError: (err: any) => {
    },
  })

  React.useEffect(() => {
    if (values.new_scheduledStartDatetime !== null && values.new_scheduledEndDatetime === null) {
      if (moment(values.new_scheduledStartDatetime).minutes())
        setFieldValue("new_scheduledEndDatetime", moment(values.new_scheduledStartDatetime).add(1, 'hours').toDate()
        )
    }
  }, [values.new_scheduledStartDatetime])

  React.useEffect(() => {


    if (values.scheduledStartDatetime !== null && values.scheduledEndDatetime === null) {

      console.log(moment(values.scheduledStartDatetime).minutes());
      if (moment(values.scheduledStartDatetime).minutes())
        setFieldValue("scheduledEndDatetime", moment(values.scheduledStartDatetime).add(1, 'hours').toDate())


    }
  }, [values.scheduledStartDatetime])


  if (!isOpen) return <></>
  return (
    <ModalWraper
      title="Rendez-vous"
      saveBtnText='Valider'
      cancelBtnText={readonly ? "Fermer" : "Annuler"}
      onSave={handleSubmit}
      onCancel={onclose}
      isOpen={isOpen}
      disableSave={readonly}
    >
      <LoadingLayer open={isCreating || isUpdating} />
      <Stack paddingBottom={5} gap={2}>
        {action === "create" &&
          <>
            <FormControl >
              <Stack direction="row" alignItems="center" gap={2}>
                <Typography variant="P14_Lh130" style={{ color: palette.text_light2.main }}>
                  Type
                </Typography>
                <RadioGroup
                  aria-labelledby="rdv-radio-buttons-group-label"
                  defaultValue="installation"
                  name="radio-buttons-group"
                  sx={{ dieplay: "flex", flexDirection: "row" }}
                  value={values.interventionType}
                  onChange={handleChange("interventionType")}
                >
                  <FormControlLabel disabled={readonly} value="installation" control={<Radio color="success" />} label="Installation" />
                  <FormControlLabel disabled={readonly} value="devis" control={<Radio color="warning" />} label="Devis" />
                </RadioGroup>
              </Stack>
            </FormControl>
            <div style={{ position: "relative" }}>
              <TextareaAutosize
                aria-label="minimum height"
                name="text"
                readOnly={readonly}
                minRows={7}
                placeholder=""
                value={values.text}
                onChange={handleChange("text")}
                style={{
                  width: "100%",
                  backgroundColor: palette.bg_primary.main,
                  borderRadius: "0.5rem",
                  border: "none",
                  outline: "none",
                  color: palette.text_light.main,
                  padding: " 1rem",
                  boxSizing: "border-box"
                }}
              />

            </div>
            <Divider sx={{ backgroundColor: palette.grey[500] }} />
            <Typography variant='P16_Lh130' style={{ color: palette.text_light.main, marginBottom: "0.5rem" }}>
              Date Intervention
            </Typography>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Stack gap={3} >

                <MobileDatePicker
                  label="La date"
                  onChange={(value) => {
                    setFieldValue("scheduledStartDatetime", value)
                  }}
                  value={values.scheduledStartDatetime ? moment(values.scheduledStartDatetime, "YYYY-MM-DD").toDate() : null}
                  readOnly={readonly/* || action === "update"*/}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      sx={{
                        svg: { color: palette.text_light },
                        input: { color: palette.text_light },
                        label: { color: palette.text_light },

                      }} />
                  )}
                />
                <MobileTimePicker
                  label="Heure début"
                  value={values.scheduledStartDatetime ? moment(values.scheduledStartDatetime, "HH:mm").toDate() : null}
                  onChange={(value) => {
                    if (!value) return
                    const date = moment(values.scheduledStartDatetime).format("YYYY-MM-DD")
                    const start_time = moment(value).format("HH:mm")
                    const dateTime = moment(date + ' ' + start_time, 'YYYY-MM-DD HH:mm').toDate()
                    setFieldValue("scheduledStartDatetime", dateTime)
                  }}

                  readOnly={readonly || /*action === "update" || */values.scheduledStartDatetime === null}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      sx={{
                        svg: { color: palette.text_light },
                        input: { color: palette.text_light },
                        label: { color: palette.text_light },

                      }} />
                  )}
                />

                <MobileTimePicker
                  label="Heure fin"
                  onChange={(value) => {
                    const date = moment(values.scheduledStartDatetime).format("YYYY-MM-DD")
                    const end_time = moment(value).format("HH:mm")
                    const dateTime = moment(date + ' ' + end_time, 'YYYY-MM-DD HH:mm').toDate()
                    setFieldValue("scheduledEndDatetime", dateTime)
                  }}
                  value={values.scheduledEndDatetime}
                  readOnly={readonly || /*action === "update" || */values.scheduledStartDatetime === null}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      sx={{
                        svg: { color: palette.text_light },
                        input: { color: palette.text_light },
                        label: { color: palette.text_light },
                      }} />
                  )}
                />



              </Stack>
            </LocalizationProvider>
          </>
        }
        {action !== "create" &&
          <>
            <FormControl >
              <Stack direction="row" alignItems="center" gap={2}>
                <Typography variant="P14_Lh130" style={{ color: palette.text_light2.main }}>
                  Statut
                </Typography>
                <RadioGroup
                  aria-labelledby="rdv-radio-buttons-group-label"
                  name="radio-buttons-group"
                  sx={{ dieplay: "flex", flexDirection: "row" }}
                  value={values.etat}
                  onChange={handleChange("etat")}
                >
                  <FormControlLabel disabled={readonly} value={"termine"} control={<Radio color="success" />} label="Client Présent" />
                  <FormControlLabel disabled={readonly} value={"annule"} control={<Radio color="error" />} label="Annulé" />

                </RadioGroup>
              </Stack>
            </FormControl>
            <Button size='small' variant="outlined" onClick={handleReport}>
              Reporter
            </Button>
            <Collapse in={values.etat === "annule"}>
              <Stack gap={1}>
                <Typography variant="P16_Lh130" >
                  Raison d'annulation
                </Typography>
                <TextareaAutosize
                  aria-label="minimum height"
                  name="raison_annulation"
                  readOnly={readonly}
                  minRows={4}
                  placeholder=""
                  value={""}
                  onChange={(e: any) => { }}
                  style={{
                    width: "100%",
                    backgroundColor: palette.bg_primary.main,
                    borderRadius: "0.5rem",
                    border: "none",
                    outline: "none",
                    color: palette.text_light.main,
                    padding: " 1rem",
                    boxSizing: "border-box"
                  }}
                />
              </Stack>

            </Collapse>
            <Collapse in={will_report && values.etat != "annule" && values.etat != "termine"}>
              <Stack gap={1}>
                <Typography variant="P16_Lh130" >
                  nouvelle programation
                </Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <MobileDatePicker
                    label="Reporter à la date"
                    onChange={(value) => {
                      setFieldValue("new_scheduledStartDatetime", value)
                    }}
                    value={values.new_scheduledStartDatetime}
                    readOnly={readonly}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        sx={{
                          svg: { color: palette.text_light },
                          input: { color: palette.text_light },
                          label: { color: palette.text_light },

                        }} />
                    )}
                  />
                  <MobileTimePicker
                    label="Heure début"
                    value={values.new_scheduledStartDatetime ? moment(values.new_scheduledStartDatetime, "HH:mm").toDate() : null}
                    onChange={(value) => {
                      if (!value) return
                      const date = moment(values.new_scheduledStartDatetime).format("YYYY-MM-DD")
                      const start_time = moment(value).format("HH:mm")
                      const dateTime = moment(date + ' ' + start_time, 'YYYY-MM-DD HH:mm').toDate()
                      setFieldValue("new_scheduledStartDatetime", dateTime)
                    }}

                    readOnly={readonly || values.new_scheduledStartDatetime === null}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        sx={{
                          svg: { color: palette.text_light },
                          input: { color: palette.text_light },
                          label: { color: palette.text_light },

                        }} />
                    )}
                  />

                  <MobileTimePicker
                    label="Heure fin"
                    onChange={(value) => {
                      const date = moment(values.new_scheduledStartDatetime).format("YYYY-MM-DD")
                      const end_time = moment(value).format("HH:mm")
                      const dateTime = moment(date + ' ' + end_time, 'YYYY-MM-DD HH:mm').toDate()
                      setFieldValue("new_scheduledEndDatetime", dateTime)
                    }}
                    value={values.new_scheduledEndDatetime ? moment(values.new_scheduledEndDatetime, "HH:mm").toDate() : null}
                    readOnly={readonly || values.new_scheduledStartDatetime === null}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        sx={{
                          svg: { color: palette.text_light },
                          input: { color: palette.text_light },
                          label: { color: palette.text_light },
                        }} />
                    )}
                  />

                </LocalizationProvider>
              </Stack>
            </Collapse>
          </>
        }
        <RealiseParBlock user={data?.installateurUser} />

      </Stack >
    </ModalWraper >
  )
}
