import {
  CreateCompteRenduRequest,
  CreateCompteRenduResponse,
  InterventionCompteRendu,
  UpdateCompteRenduRequest,
  UpdateCompteRenduResponse,
} from "../@types";
import { GenricService } from "./geniric.service";

export class CompteRenduInvService {

  static async fetchAll(): Promise<InterventionCompteRendu> {
    const route = `/techniciensApi/v1/interventionCompteRendus`;
    return GenricService.fetch<InterventionCompteRendu>(route, {});
  }

  static async fetchOne(id: number): Promise<InterventionCompteRendu> {
    const route = `/techniciensApi/v1/interventionCompteRendus`;
    return GenricService.fetchOneById<InterventionCompteRendu>(route, id);
  }

  static async createOne(
    data: CreateCompteRenduRequest
  ): Promise<CreateCompteRenduResponse> {
    const route = `/techniciensApi/v1/interventionCompteRendus`;
    return GenricService.PostOne<
      CreateCompteRenduRequest,
      CreateCompteRenduResponse
    >(route, data);
  }

  static async updateOne(
    data: UpdateCompteRenduRequest
  ): Promise<UpdateCompteRenduResponse> {
    const route = `/techniciensApi/v1/interventionCompteRendus/${data.id}`;
    return GenricService.PatchOne<
      UpdateCompteRenduRequest,
      UpdateCompteRenduResponse
    >(route, data);
  }
}
