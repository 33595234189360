import axios from "axios";




// TODO : put this feature in the correct file. as a provider/context ?
function getUserFromStorage() {
    const user_str = localStorage.getItem("user");
    if (user_str === null) return null;
    return JSON.parse(user_str);
}


export function useAxiosInstance() {
    const user = getUserFromStorage();

    return axios.create({
        baseURL: "https://api.borneforyou.co/techniciensApi/v1",
        headers: {
            Authorization: `Bearer ${user?.accessToken}`,
        },
    });
};
