import React, { createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useMemo, useState } from "react"
import { useMutation, } from '@tanstack/react-query'


import { LoadingBlock } from "../../components/util"
import { getUserFromStorage } from "../../helpers"
import { AuthService } from "../../services_api/services/auth.service"
import { ProfileServices } from "../../services_api/services/profile.services"
import { LoginRequest, LoginResponse } from "../../services_api"


export type currentUserType = LoginResponse | null
type AuthContextType = {
    currentUser: currentUserType
    setCurrentUser: Dispatch<SetStateAction<currentUserType>>
    signIn: (data: LoginRequest) => void
    signOut: () => void
    serverState: {
        isLoading: boolean,
    }
}

const AuthContext = createContext<AuthContextType>({
    currentUser: null,
    setCurrentUser: () => { },
    signIn: (data: any) => { },
    signOut: () => { },
    serverState: {
        isLoading: false,
    }
})


type AuthProviderProps = {
    children?: React.ReactNode
  };
export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
    const [currentUser, setCurrentUser] = useState<currentUserType>(null)
    const [loadingState, setLoadingState] = useState<boolean>(true)

    
    const { mutate: get_profile_mutation, isLoading: geting_profile } = useMutation(ProfileServices.GetMe, {
        onSuccess: (data) => {
            setCurrentUser((prev) => ({ ...prev, ...data }))
        },
        onError: (err: any) => {

        },
    })

    const { mutate: signin_mutate, isLoading: signing } = useMutation(AuthService.SignIN, {
        onSuccess: (data) => {
            setCurrentUser((prev: any) => ({ ...prev, ...data }))
        },
        onError: (err: any) => {

        }
    })
    const { mutate: signout_mutate, isLoading: signing_out } = useMutation(AuthService.SignOut, {
        onSuccess: (data) => {
            setCurrentUser(null)
        },
        onError: (err: any) => {

        }
    })

    
    const getMe = (data: any) => {
        get_profile_mutation(data)
    }
    const signIn = (data: LoginRequest) => {
        signin_mutate(data)
    }
    const signOut = () => {
        signout_mutate()
    }

    const contextValue =
    {
        currentUser,
        setCurrentUser,
        serverState: {
            isLoading: geting_profile || signing || signing_out,
        },
        signIn,
        signOut,
    }

    useEffect(() => {
        const user = getUserFromStorage()
        if (user === null) {
            setLoadingState(false)
            return
        }
        setCurrentUser(user)
        setLoadingState(false)
        getMe(user);
    }, [])



    return (
        <AuthContext.Provider value={contextValue}>
            {loadingState ? <LoadingBlock height={"100vh"} /> : children}
        </AuthContext.Provider>
    )
}



export const useAuthentication = () => useContext(AuthContext)