import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { relative } from 'path';
import { useFormik } from 'formik';
import { Product } from '../../../../../../../services_api';
import { Card, Stack, TextareaAutosize, TextField, Typography, useTheme } from '@mui/material';
import { LineDevisType } from './CreationDevisModal';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';


type NewProductModalProps = {
    open: boolean,
    onClose: () => void
    onSave: (prod: Product) => void
}

export const NewProductModal = React.memo((props: NewProductModalProps) => {
    const { open, onClose, onSave } = props
    const { palette } = useTheme()
    const { values, handleChange, handleSubmit, setValues, errors, setFieldValue } = useFormik<Product>({
        initialValues: {
            title: "",
            price: "" as any,
            description: ''
        },
        onSubmit: (values: Product) => {
            onSave(values)
        }
    })



    return (

        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle sx={{ backgroundColor: palette.bg_dark.main }} id="alert-dialog-title">
                création de neveau produit
            </DialogTitle>
            <Card sx={{ backgroundColor: palette.bg_dark.main }}>
                <CardContent>
                    <Stack gap={2}>
                        <TextField
                            id="title"
                            type="text"
                            label="Nom du produit"
                            value={values.title}
                            onChange={handleChange("title")}
                            style={{ backgroundColor: palette.bg_primary.main }}
                        />

                        <TextField
                            id="prix-unitaire"
                            type="number"
                            label="Prix unitaire"
                            value={values.price}
                            //disabled
                            onChange={handleChange("price")}
                            style={{ backgroundColor: palette.bg_primary.main }}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        />

                    </Stack>

                </CardContent>

                <CardActions disableSpacing sx={{ display: "flex", justifyContent: 'space-between', backgroundColor: palette.bg_primary.main }}>
                    <Button color="error" onClick={onClose}>
                        <Typography variant="P12_Lh130">
                            Annuler
                        </Typography>
                    </Button>
                    <Button color="success" onClick={() => {
                        handleSubmit()
                        onClose()
                    }}
                        autoFocus
                    >
                        <Typography variant="P12_Lh130">
                            Ajouter
                        </Typography>
                    </Button>
                </CardActions>

            </Card>

        </Dialog>

    );
})
