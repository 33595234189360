import { useInfiniteQuery } from "@tanstack/react-query"
import { matrixToList } from "../helpers"
import { IndexResult } from "../services_api/@types"
import { GenricService } from "../services_api/services/geniric.service"

export function useInfinitFetche<T>(queryName: string, route: string, filter: any, limit: number = 10, offset: number = 0) {

    const QUERY_QEY = filter ? [queryName, ...Object.keys(filter).map(key => `${key}=${JSON.stringify(filter[key])}`)] : [queryName]
    const query_params = { ...filter, limit, offset }
    const {
        data,
        error,
        isLoading,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
        refetch,
    } = useInfiniteQuery<IndexResult<T>, Error>(
        QUERY_QEY,
        ({ pageParam = query_params }: any) => GenricService.fetchInfinit<T>(route, pageParam), {
        getNextPageParam: (lastPage, pages) => lastPage.next !== null ? { offset: lastPage.next, limit } : undefined,
    })
    const resultsCount = data?.pages.length ? data?.pages[0].count : 0
    const results_table = data?.pages.map(item => item.rows)
    const results = matrixToList(results_table)
    return { results, isLoading, isFetchingNextPage, fetchNextPage, refetch, error, hasNextPage, resultsCount }
}