import { Box, Stack, Typography, Chip, Divider, Button, useTheme } from '@mui/material'
import React, { Fragment, useMemo } from 'react'
import { AgendaIcon, CheckedIcon } from '../../icons'
import { LoadingBlock, MyChip, ScreenHeader } from '../../util'
import { InterventionElement, SectionHeader } from './components'
import { useQuery } from '@tanstack/react-query'
import { getCalendar, groupBy, getDateTimeString } from '../../../helpers'
import moment from 'moment'
import { useInfinitFetche } from '../../../hooks'
import { InterventionRdv } from '../../../services_api'
import { useInfinitInterventions } from '../../../services_api/services'



export const Intervention = () => {
    const { palette } = useTheme()
    const {
        results: interventions,
        isLoading,
        isFetchingNextPage: loading_more,
        fetchNextPage: loadMore,
        hasNextPage: can_load_more
    } = useInfinitInterventions(10)// 10 == page size ,it can be changed

    const groupedDataMap = useMemo(() => {
        if (!interventions) return {}
        return groupBy(interventions ?? [], v => v.scheduledStartDatetime.substring(0, 10))
    }, [interventions])


    return (
        <Stack sx={{ backgroundColor: palette.bg_primary.main, minHeight: "100vh" }}>
            <ScreenHeader title='Interventions' />
            {isLoading &&
                <LoadingBlock />
            }
            <Stack paddingBottom={1}>
                <Stack direction="row" gap={2} sx={{ paddingX: 2, paddingY: 1, backgroundColor: palette.bg_dark_green.main }} alignItems="center">
                    <AgendaIcon size="2.5rem" color={palette.text_light.main} />
                    <Stack gap={0.5} sx={{ color: palette.text_light.main }}>
                        <Typography variant="P18_Lh130">
                            Interventions à venir
                        </Typography>
                        <Typography variant="P18_Lh130">
                            {groupedDataMap[moment().format('YYYY-MM-DD')]?.length || "Aucun"} rendez-vous aujourd'hui
                        </Typography>
                    </Stack>
                </Stack>

                {
                    Object.keys(groupedDataMap).sort().map((time) => (
                        <Fragment key={time}>
                            <SectionHeader title={getCalendar(time)} />
                            {
                                groupedDataMap[time].map((itervention, index) => (
                                    <Fragment key={index}>
                                        <InterventionElement
                                            type={itervention.interventionType}
                                            client={`
                                            ${itervention.projet?.customer?.firstName} 
                                            ${itervention.projet?.customer?.lastName}
                                            `}
                                            adresse={`${itervention.projet?.addressPostalCode} - ${itervention.projet?.addressCity}`}
                                            date_time={getDateTimeString(itervention.scheduledStartDatetime)}
                                            idProjet={itervention.projetId}
                                            idString={itervention.projet?.idString}
                                        />
                                        <Divider sx={{ backgroundColor: palette.grey[500] }} />
                                    </Fragment>

                                ))
                            }
                        </Fragment>
                    ))
                }

                {loading_more ?
                    <LoadingBlock />
                    :
                    can_load_more && <Button
                        onClick={() => loadMore()}
                        sx={{ marginX: "auto", textTransform: "none", maxWidth: 200, marginY: 3 }}
                    >
                        <Typography variant="P16_Lh130" sx={{ color: palette.info.main, margin: "auto", textAlign: "center" }} >
                            Voir Plus d'interventions à venir
                        </Typography>
                    </Button>
                }


            </Stack>
        </Stack>
    )
}



