import React from 'react';
import MyDrawer from './components/navigation/MyDrawer';
import { Agenda, Devis, Intervention, Projet, Projets, SuiviCommercial } from './components/screens';
import { ScreenHeader } from './components/util';
import { Routes, Route, Link } from "react-router-dom";

import { useAuthentication } from './state';
import { LoginScreen } from './components/screens/auth';


function App() {
  const { currentUser } = useAuthentication()
  if (currentUser === null)
    return (
      <LoginScreen />
    )
  return (
    <div className="App">

      <Routes>
        <Route path="/" element={<Intervention />} />
        <Route path="agenda" element={<Agenda />} />
        <Route path="projets" element={<Projets />} >
        </Route>
        <Route path="projets/:idProjet" element={<Projet />} />
        <Route path="devis" element={<Devis />} />
        <Route path="suivi_commercial" element={<SuiviCommercial />} />
        <Route path="*" element={<div> 404</div>} />
      </Routes>
    </div>
  );
}

export default App;

