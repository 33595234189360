import { ListItem, ListItemButton, MenuItem, Stack, Typography,useTheme } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { interventionType } from '../../../../services_api'
import { CheckedIcon } from '../../../icons'
import { MyChip } from '../../../util'

type InterventionElementProps = {
    type: interventionType,
    adresse: string,
    client: string,
    date_time: string,
    idString?: string,
    idProjet: number,

}
export const InterventionElement = (props: InterventionElementProps) => {
    const { type, adresse, client, date_time, idProjet, idString } = props

    const { palette } = useTheme()
    return (
        <Link to={`/projets/${idProjet}`} style={{ textDecoration: "none" }} >
            <ListItemButton >
                <Stack sx={{ paddingY: 1, width: "100%" }} direction="row" alignItems="center" justifyContent="space-between" >
                    <Stack direction="row" alignItems="center" gap={3} >
                        <CheckedIcon size="3rem" color={type === "devis" ? palette.warning.main : palette.success.main} />
                        <Stack gap={0.2} sx={{ color: palette.text_light.main }} alignItems="flex-start">
                            <Typography variant="h3">
                                Rendez-vous {type}
                            </Typography>
                            <Typography variant="P12_Lh130">
                                {client}- {adresse}
                            </Typography>
                            <MyChip label={date_time} borderColor={palette.grey[500]} fontSize='12px' color={palette.text_light.main} />
                        </Stack>
                    </Stack>
                    <MyChip label={`#${idString}`} borderColor={palette.grey[500]} fontSize='14px' color={palette.text_light.main} />
                </Stack>
            </ListItemButton>
        </Link>

    )
}
