import React, { useState } from 'react'
import { Autocomplete, Button, Collapse, FormControl, FormControlLabel, InputAdornment, Radio, RadioGroup, Stack, TextareaAutosize, TextField, Typography, useTheme } from '@mui/material'
import { DevisIcon } from '../../../../../../icons'
import { MyModalProps } from './@types'
import { ModalWraper } from './ModalWraper'
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import { useFormik } from 'formik'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import LigneDevis from '../../../LigneDevis'
import { Fragment, useMemo } from 'react'
import { CreateDevisRequest, Devi, DeviItem, Product, Projet } from '../../../../../../../services_api/@types'
import moment from 'moment'
import { useCreateDevis, useInfinitProducts, useUpdateDevis } from '../../../../../../../services_api/services'
import { NewProductModal } from './NewProductModal'
import { useAuthentication } from '../../../../../../../state'
import { LoadingLayer, MyChip, PdfViewer } from '../../../../../../util'
import { useQueryClient } from '@tanstack/react-query'
import { projectsKeys } from '../../../../../../../services_api/keys'
import * as yup from 'yup'
import { getUniqueId } from '../../../../../../../helpers'
import { parsePhoneNumber } from 'libphonenumber-js'
import { zOptThru } from '../../../../../../../z/utils/primitives'
import { NumericFormat } from 'react-number-format'
import { PercentageNumberField } from '../../../../../../forms'


const devisSchema = yup.object().shape({
  projetId: yup.number().required("requis"),
  date: yup.date().required("requis"),
  offreValableJusquAu: yup.date().required("requis"),
  items: yup.array().min(1, "il faut q'il y a au moins une ligne de devi"),
  isAccepted: yup.boolean().required(),
  isRefused: yup.boolean().required(),
})

type CreationDevisModalProps = MyModalProps & {
  data?: Devi
  projet: Projet

}
export type LineDevisType =
  DeviItem & {
    _id: string;
    text?: string;
  }




export const CreationDevisModal = (props: CreationDevisModalProps) => {
  const { onclose = () => { }, projetId, isOpen = false, projet, data, readonly = false, action } = props

  const { customer: client, addressCity, addressLine1, addressPostalCode } = projet

  const [New_prodModalOpen, setNew_prodModalOpen] = useState(false)
  const { palette } = useTheme()
  const [new_products, setNew_products] = useState<Product[]>([])




  /** manage form state , errors , focus, change, and submition */
  const { values, isValid, touched, handleChange, handleSubmit, setFieldValue, setFieldTouched } =
    useFormik<Omit<CreateDevisRequest, 'items'> & { items: (LineDevisType | null)[] }>({
      initialValues: {
        projetId,
        date: (data?.date) ? moment(data.date).toDate() : moment().toDate(),
        offreValableJusquAu: (data?.offreValableJusquAu) ? moment(data?.offreValableJusquAu).toDate() : moment().add(15, 'days').toDate(),
        items: (data?.items ?? []).map(item => ({ ...item, _id: getUniqueId(), text: "" })),
        objet: data?.objet ?? "",
        firstInstallmentPercentage: data?.firstInstallmentPercentage ?? 30,
        commentaire: data?.commentaire ?? "",
        isAccepted: data?.isAccepted ?? false,
        isRefused: data?.isRefused ?? false
      },
      validationSchema: devisSchema,
      initialErrors: { date: "requise" },
      onSubmit: (values) => {
        switch (action) {
          case "create":
            const { items: createItems, offreValableJusquAu, ...rest } = values
            create({
              ...rest,
              date: moment(offreValableJusquAu).format("YYYY-MM-DD"),
              offreValableJusquAu: moment(offreValableJusquAu).format("YYYY-MM-DD"),
              items: createItems.filter(item => item !== null)
            } as any);
            break;
          case "update":
            if (data?.id) {
              values.items = values.items.filter(item => item !== null)
              update({ ...values, id: data.id } as any);
            }
            break;
          default: break
        }
      }
    })
  console.log(values.items);

  /** fetch products for auto completion */
  const { products } = useInfinitProducts()

  /** get current user */
  const { currentUser } = useAuthentication()

  /** check if last element in values.items is empty ( === empty_value) */
  const lastElemntIsEmpty = () => values.items.length > 0 && values.items[values.items.length - 1] === null

  /** add emty value when button add first clicked */
  const addEmptyValue = () => {
    if (lastElemntIsEmpty()) return
    setFieldValue("items", [...values.items, null])
  }

  /** replace empty element with item when product was choosen */
  const addNewLine = (e: any, value: Product | null, reason: any) => {
    if (value === null) return
    setFieldValue("items",
      [...values.items.filter(val => val !== null),
      {
        _id: getUniqueId(),
        title: value.title ?? "",
        unitAmount: value.price ?? 0
      }
      ])
  }

  /**  */
  const getOrUpdatedProductList = React.useCallback((prod?: Product) => {
    if (!prod) return [...products, ...new_products]
    setNew_products(prev => [...prev, prod])
    addNewLine(null, prod, null)
    return [...products, ...new_products, prod]
  }, [products, new_products])

  /** delete one item by temporary _id from values.items */
  const deleteLine = (id: string) => {
    return () => {
      setFieldValue("items", values.items.filter(item => item !== null && item._id !== id))
    }
  }

  /** update line by temporary _id */
  const updateLine = (id: string, new_value: LineDevisType) => {
    setFieldValue(
      "items",
      values.items.map(item => {
        if (item === null) return item
        if (item._id === id) return new_value
        return item
      })
    )
  }

  React.useEffect(() => {
    if (values.date && !touched.offreValableJusquAu)
      setFieldValue("offreValableJusquAu", moment(values.date).add(15, 'days').toDate())
  }, [values.date])

  const queryClient = useQueryClient()
  const refreshProject = () => {
    queryClient.invalidateQueries([projectsKeys.for_many, projetId])
  }

  const { mutate: create, isLoading: isCreating } = useCreateDevis(
    () => {
      refreshProject()
      onclose()
    }
  )

  const { mutate: update, isLoading: isUpdating } = useUpdateDevis(
    () => {
      refreshProject()
      onclose()
    }
  )

  if (!isOpen) return <></>
  return (
    <ModalWraper
      title={action === "create" ? "Création de devis" : "Modification de devis"}
      saveBtnText={action === "create" ? 'Enregistrer' : "Modifier"}
      onSave={handleSubmit}
      cancelBtnText={readonly ? "Fermer" : "Annuler"}
      onCancel={onclose}
      isOpen={isOpen}
      disableSave={readonly || !isValid}
    >

      < Stack gap={3} paddingBottom={10}>
        <LoadingLayer open={isCreating || isUpdating} />
        {/** vos cordonnées */}
        <Stack color={palette.grey[500]} gap={1}>
          <Typography variant="P18_Lh130" sx={{ color: palette.grey[400] }} >
            Vos coordonnnnées :
          </Typography>
          <Stack gap={0.2} color={palette.grey[500]}>
            <Typography variant="P16_Lh130" sx={{ color: palette.grey[400] }} >
              {currentUser?.user?.fullName}
            </Typography>

            <Typography variant="P16_Lh130" sx={{ color: palette.grey[400] }} >
              {currentUser?.user.email}
            </Typography>
          </Stack>

        </Stack>
        <Stack gap={1}>
          <Typography variant="P18_Lh130" sx={{ color: palette.grey[400] }}>
            Le client :
          </Typography>
          <Stack gap={0.2} color={palette.grey[500]}>
            <Typography variant="P16_Lh130">
              {client?.fullName}
            </Typography>
            <Typography variant="P16_Lh130">
              {addressLine1}
            </Typography>

            <Typography variant="P16_Lh130">
              {addressPostalCode} {addressCity}
            </Typography>
            <Typography variant="P16_Lh130">
              France
            </Typography>
            <Typography variant="P14_Lh130">
              {zOptThru(client?.phoneNumber, (x: string) => parsePhoneNumber(x, 'FR').formatNational())}
            </Typography>
          </Stack>
        </Stack>


        <Stack gap={1}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <MobileDatePicker
              label="Date du devis"
              onChange={(value) => setFieldValue("date", value)}
              value={values.date}
              readOnly={readonly}
              renderInput={(params: any) => (
                <TextField {...params} />
              )}
            />
            <MobileDatePicker
              label="Offre valable jusqu'au"
              onChange={(value) => {
                setFieldValue("offreValableJusquAu", value)
                setFieldTouched('offreValableJusquAu', true)
              }}
              value={values.offreValableJusquAu}
              readOnly={readonly}
              renderInput={(params: any) => (
                <TextField {...params} />
              )}
            />
          </LocalizationProvider>
        </Stack>

        <Typography variant="h2">
          Ligne(s) de devis
        </Typography>

        {
          (values.items ?? []).map((item, index) => (
            <Fragment key={item === null ? item : item.title}>
              {item !== null ?
                <LigneDevis
                  data={item}
                  onDelete={deleteLine(item._id)}
                  onUpdate={updateLine}
                //autoCompleetOptions={getOrUpdatedProductList() ?? []}
                />
                :
                <></>
              }
            </Fragment>

          ))
        }
        {
          New_prodModalOpen &&
          <NewProductModal
            open={true}
            onSave={(prod: Product) => getOrUpdatedProductList(prod)}
            onClose={() => setNew_prodModalOpen(false)}
          />
        }
        <Stack alignItems="center" gap={3}>
          {lastElemntIsEmpty() &&
            < Autocomplete
              noOptionsText={
                <Button onClick={() => setNew_prodModalOpen(true)}>
                  Créer un produit
                </Button>
              }
              disablePortal
              id="combo-box-demo"
              options={getOrUpdatedProductList() ?? []}
              value={null}
              onChange={addNewLine}
              getOptionLabel={option => option.title}
              sx={{ width: "100%" }}
              getOptionDisabled={(prod) => values.items.filter(ldv => ldv !== null).filter((ldv: any) => ldv.title === prod.title).length > 0}
              renderInput={(params) =>
                <TextField
                  onChange={handleChange("search")}
                  style={{
                    backgroundColor: palette.bg_primary.main
                  }}
                  {...params}
                  label="Saisir un produit/service à vendre" />}
            />
          }
          <Button
            startIcon={<DevisIcon size="1.2rem" />}
            style={{ borderRadius: 30, textTransform: 'none', }}
            variant="contained"
            color="success"
            onClick={addEmptyValue}
          >
            Ajouter une ligne de devis
          </Button>
        </Stack>
        <Typography variant="P18_Lh130" sx={{ textAlign: "center", backgroundColor: "#3d4354", paddingY: 1 }} >
          TOTAL: {
            values.items?.map(item => (item?.quantity ?? 1)*(item?.unitAmount ?? 0))?.reduce((x, y) => x+y, 0).toLocaleString("fr-FR", {
              style: "currency",
              currency: "EUR",
              minimumFractionDigits: 2,
            })
          } TTC
        </Typography>
        <Stack gap={4}>
          <Stack gap={1.5}>
            <Typography variant="h3">
              Pourcentage d'acompte
            </Typography>
            <PercentageNumberField
              value={values.firstInstallmentPercentage}
              onChange={(value: any) => setFieldValue("firstInstallmentPercentage", value)}
              readOnly={readonly}
              min={0}
              max={100}
              placeholder=""
              style={{
                width: "100%",
                backgroundColor: palette.bg_primary.main,
                borderRadius: "0.5rem",
                border: "none",
                outline: "none",
                color: palette.text_light.main,
                padding: " 1rem",
                boxSizing: "border-box"
              }}
            />
          </Stack>
          <Stack gap={1.5} >
            <Typography variant="h3">
              Objet
            </Typography>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={4}
              value={values.objet}
              onChange={handleChange("objet")}
              readOnly={readonly}
              placeholder=""
              style={{
                width: "100%",
                backgroundColor: palette.bg_primary.main,
                borderRadius: "0.5rem",
                border: "none",
                outline: "none",
                color: palette.text_light.main,
                padding: " 1rem",
                boxSizing: "border-box"
              }}
            />
          </Stack>
          <Stack gap={1.5}>
            <Typography variant="h3">
              Commentaire
            </Typography>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={4}
              value={values.commentaire}
              onChange={handleChange("commentaire")}
              readOnly={readonly}
              placeholder=""
              style={{
                width: "100%",
                backgroundColor: palette.bg_primary.main,
                borderRadius: "0.5rem",
                border: "none",
                outline: "none",
                color: palette.text_light.main,
                padding: " 1rem",
                boxSizing: "border-box"
              }}
            />
          </Stack>
        </Stack>

      </Stack>


    </ModalWraper >
  )
}



