import { AxiosInstance } from "axios";

/*
    const formSchema = yup.object().shape({
        items: yup.array().of(
            yup.object().shape({
                title: yup.string().required('Veuillez saisir un titre'),
                text: yup.string(),
                quantity: yup.number().required('Veuillez saisir une quantité').min(1, 'La quantité doit être positive'),
                unitAmount: yup.number().required('Veuillez saisir un prix unitaire HT').min(1, 'Le prix unitaire doit être positif'),
            })
        ).required('Veuillez saisir au moins un article'),
        vatRatePercentage: yup.number().oneOf([5.5, 20]).required('Veuillez choisir un taux de TVA'),
        date: yup.date().required('Veuillez saisir une date'),
        offreValableJusquAu: yup.date().required('Veuillez saisir une date').min(yup.ref('date'), 'La date de validité doit être ultérieure à la date du devis'),
        objet: yup.string().required('Veuillez saisir un objet'),
        commentaire: yup.string(),
        firstInstallmentPercentage: yup.number().required('Veuillez saisir un pourcentage').min(0).max(100),
    }); 
*/

interface CreateDeviPayload {
    projetId: number;
    items: {
        title: string;
        text?: string;
        quantity: number;
        unitAmount: number;
    }[];
    vatRatePercentage: number;
    date: string;
    offreValableJusquAu: string;
    objet: string;
    commentaire?: string;
    firstInstallmentPercentage: number;
}

export async function submitCreateDevi(payload: CreateDeviPayload, axiosInstance: AxiosInstance) {
    const response = await axiosInstance.post('/devis', payload);
    return response.data;
}


export async function fetchDeviTemplates(projetId: number, axiosInstance: AxiosInstance) {
    const response = await axiosInstance.get(`/deviTemplates?projetId=${encodeURIComponent(projetId)}`);
    return response.data;
}


export async function fetchProducts(projetId: number, axiosInstance: AxiosInstance) {
    const response = await axiosInstance.get(`/products?projetId=${encodeURIComponent(projetId)}`);
    return response.data;
}
