import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Stack, useTheme } from '@mui/material';
import { useAuthentication } from '../../../state';
import { Formik, useFormik } from 'formik';
import * as yup from 'yup';

const SigninSchema = yup.object().shape({
    email: yup.string().email("email format n'est pas valid").required("email is required"),
    password: yup.string().required("password is required").min(3, "le mot de passe doit etre min trois caractères de longueur"),
});


export function LoginScreen() {
    const { palette } = useTheme();
    const { signIn } = useAuthentication()
    const { values, handleSubmit, handleChange, handleBlur, errors, touched, isValid } = useFormik({
        initialValues: {
            email: '',
            password: ""
        },
        onSubmit: data => signIn(data),
        validationSchema: SigninSchema
    })

    return (
        <Stack sx={{ backgroundColor: palette.bg_primary.main, minHeight: "100vh" }}>

            <Container maxWidth="xs" >
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img src="https://uploads-ssl.webflow.com/628919fe7f4f99ed670f7007/632b2052a7bebb16e0cbdddf_Groupe%2086.png" style={{
                        width: '80%',
                        margin: '1em 0',
                    }} />
                    <Box sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            value={values.email}
                            onChange={handleChange("email")}
                            onBlur={handleBlur("email")}
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                            id="email"
                            label="Adresse e-mail"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            value={values.password}
                            onChange={handleChange("password")}
                            onBlur={handleBlur("password")}
                            error={Boolean(touched.password && errors.password)}
                            helperText={touched.password && errors.password}
                            name="password"
                            label="Mot de passe"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        {/*<FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                />*/}
                        <Button
                            onClick={(e: any) => handleSubmit()}
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={!isValid}
                        >
                            S'identifier
                        </Button>

                    </Box>
                </Box>
            </Container>
        </Stack>



    );
}