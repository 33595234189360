import React from 'react'
import { Button, Collapse, FormControl, FormControlLabel, Radio, RadioGroup, Stack, TextareaAutosize, TextField, Typography, useTheme } from '@mui/material'

import { MyModalProps } from './@types'
import { ModalWraper } from './ModalWraper'

import { useFormik } from 'formik'

import { Devi } from '../../../../../../../services_api/@types'
import moment from 'moment'
import { DevisService, useUpdateDevis } from '../../../../../../../services_api/services'

import { LoadingLayer, MyChip, PdfViewer } from '../../../../../../util'
import { useQueryClient } from '@tanstack/react-query'
import { projectsKeys } from '../../../../../../../services_api/keys'
import { getDateTimeString } from '../../../../../../../helpers'
import { RealiseParBlock } from '../../../RealiseParBlock'



type ViewDeviModalProps = MyModalProps & {
    data: Devi
}

/** this will shown when you try to only view devis ( action === read) */

export const ViewDeviModal = React.memo((props: ViewDeviModalProps) => {
    const { isOpen, data, onclose, projetId } = props
    const { palette } = useTheme()
    const { values, handleChange, handleSubmit } = useFormik<{ isApproved: "approve" | "refuse" | "none", refusalReason: string }>({
        initialValues: {
            isApproved: data?.isAccepted ? "approve" : data?.isRefused ? 'refuse' : "none",
            refusalReason: data?.refusalReason || ""
        },
        onSubmit: () => {
            if (data?.id)
                update(
                    {
                        id: data.id,
                        isAccepted: values.isApproved === "approve",
                        isRefused: values.isApproved === "refuse",
                        refusalReason: values.refusalReason
                    }
                )
        },

    })
    const [isSending, setIsSending] = React.useState(false)
    const disableEdit = (data.isAccepted || data.isRefused)
    const queryClient = useQueryClient()
    const refreshProject = () => {
        queryClient.invalidateQueries([projectsKeys.for_many, projetId])
    }
    const { mutate: update, isLoading: isUpdating } = useUpdateDevis(
        () => {
            refreshProject()
            onclose && onclose()
        }
    )
    if(isOpen) {
        console.log({data});
    }
    return (
        <ModalWraper
            title={"Visualisation du devis"}
            saveBtnText={'Confirmer'}
            onSave={handleSubmit}
            cancelBtnText={"Fermer"}
            onCancel={onclose}
            isOpen={isOpen}
            disableSave={disableEdit || values.isApproved === "none"}
            padding={0}
        >
            <Stack gap={3} pb={2}>
                <LoadingLayer open={isUpdating} />
                {<PdfViewer uri={data.file?.uri} />}

                <Typography variant="P18_Lh130" sx={{ textAlign: "center", backgroundColor: "#3d4354", paddingY: 1 }} >
                    TOTAL : {data.totalAmountString} TTC
                </Typography>

                {
                    !data.isSent && !isSending && (
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ width: "100%" }}
                            onClick={async () => {
                                if (data?.id) {
                                    setIsSending(true)
                                    try {
                                        await DevisService.send(data.id)
                                    } catch (error) {
                                        setIsSending(false)
                                    } finally {
                                        refreshProject()
                                    }
                                }
                            }}
                        >
                            Envoyer le devis
                        </Button>
                    )
                }
                {
                    data.isSent && (
                        <MyChip
                            sx={{ padding: "2px 8px", alignSelf: "self-start" }}
                            label={`Envoyé le  ${getDateTimeString(data.sentAt)}`}
                            fontSize='14px'
                            color={palette.text_light.main}
                            borderColor={palette.success.main}
                        />
                    )
                }

                <Stack padding={2} gap={2} >
                    <MyChip
                        sx={{ padding: "2px 8px", alignSelf: "self-start" }}
                        label={`Fait le  ${getDateTimeString(data.createdAt)}`}
                        fontSize='14px'
                        color={palette.text_light.main}
                        borderColor={palette.success.main}
                    />
                    <FormControl >
                        <Stack direction="row" alignItems="center" gap={2}>
                            <RadioGroup
                                aria-labelledby="rdv-radio-buttons-group-label"
                                name="radio-buttons-group"
                                sx={{ dieplay: "flex", flexDirection: "row" }}
                                value={values.isApproved}
                                onChange={!disableEdit ? handleChange("isApproved") : (e: any) => { }}
                            >
                                <FormControlLabel value={"approve"} control={<Radio color="success" />} label="Devis approuvé" />
                                <FormControlLabel value={"refuse"} control={<Radio color="warning" />} label="Devis refusé" />
                            </RadioGroup>
                        </Stack>
                    </FormControl>

                    <Collapse in={values.isApproved === "refuse"}>
                        <Stack gap={1}>
                            <Typography variant="P16_Lh130" >
                                Raison du refus
                            </Typography>
                            <TextareaAutosize
                                aria-label="minimum height"
                                name="raison_annulation"
                                minRows={4}
                                placeholder=""
                                value={values.refusalReason}
                                onChange={handleChange('refusalReason')}
                                readOnly={disableEdit}
                                style={{
                                    width: "100%",
                                    backgroundColor: palette.bg_primary.main,
                                    borderRadius: "0.5rem",
                                    border: "none",
                                    outline: "none",
                                    color: palette.text_light.main,
                                    padding: " 1rem",
                                    boxSizing: "border-box"
                                }}
                            />
                        </Stack>

                    </Collapse>
                    <RealiseParBlock user={data?.installateurUser} />
                </Stack>
            </Stack>
        </ModalWraper>
    )
})
