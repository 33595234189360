

import axios from "axios"
import { BACKEND_BASE_URL } from "../../consts"
import { createFormData, getAuthHeaders } from "../../helpers"



export class ProfileServices {

    static async GetMe(data: any): Promise<any> {
        const url = `${BACKEND_BASE_URL}/techniciensApi/v1/me`
        const AuthHeaders =  getAuthHeaders()
        try {
            const response = await axios.get(url, {
                headers: { ...AuthHeaders }
            })
           localStorage.setItem("user", JSON.stringify({ ...data, ...response.data }))
            return { ...data, ...response.data }

        } catch (err: any) {
           
        }
    }

    static async changePassword(data: any):Promise<void> {
        const url = `${BACKEND_BASE_URL}/auth/users/set_password/`
        const AuthHeaders = await getAuthHeaders()
        try {
            const res = await axios.post(url, data, {
                headers: { ...AuthHeaders }
            })
            return res.data
        } catch (err: any) {
           
        }
    }

    static async updateProfile(data: any): Promise<any>  {
        const url = `${BACKEND_BASE_URL}/auth/users/me/`
        const AuthHeaders = await getAuthHeaders()
        try {
            const formdata=createFormData(data)
            const res = await axios.patch(url,formdata, {
                headers: { ...AuthHeaders,'content-type': 'multipart/form-data'}
            })
            return res.data
        } catch (err: any) {
            
        }
    }

}











