import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


export function LoadingBlock(props: { height?: number|string }) {
    const {height=50}=props
    return (
        <Box sx={{ display: 'flex', height, width:"100%" }}>
            <CircularProgress size="1.5rem" sx={{ margin: "auto" }} />
        </Box>
    );
}