import { Typography, useTheme } from "@mui/material"
import { Link } from "react-router-dom"
import { EventType } from "../Agenda"


type EventProps = {
    event: EventType,
    
}

export function EventItem({ event }: EventProps) {
    const { palette } = useTheme()
    return (
        <Link to={`/projets/${event.projectId}`}>
            <div style={{
                position: "absolute",
                top: 0, left: 0,
                width: "100%", height: "100%",
                backgroundColor: event.title === "RDV installation" ? palette.tr_success.main : palette.tr_warning.main,
                color: event.title === "RDV installation" ? palette.success.main : palette.warning.main,
                display: "flex",
                borderRadius: "0.5rem",

            }}>

                <Typography variant="P10_Lh130" component="p" sx={{ textAlign: "center", margin: "auto" }}>
                    {event.title}
                </Typography>


            </div>
        </Link>
    )
}