import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Box,useTheme } from '@mui/material';


type MyProgressBarProps = {
    color: string,
    bgColor?: string
    value: number
}

export const MyProgressBar = (props: MyProgressBarProps) => {

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 8,
        borderRadius: 4,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: props.bgColor || "#777",
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: props.color,
        },
    }));

    return (
       
        <BorderLinearProgress variant="determinate" value={props.value} />
    )
}
