import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { Box, Divider, Stack, Typography, useTheme } from '@mui/material';
import { MyChip } from '../../../util';
import { ArrowRightIcon, EditIcon } from '../../../icons';
import { CustomerProperty } from '../../../../services_api/@types';
import { Fragment } from 'react';
import moment from 'moment';

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(90deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

type ActivityItemProps = {
    title: string
    tags?: { label: string, status?: "warning" | "danger" | "success" }[],
    description?: string
    icon?: React.ReactNode
    info?: string,
    expandContent?: React.ReactNode
    onClickEdit?: () => void
    onClick?: () => void
    clientsProps?: CustomerProperty[],
    createdAt?: string,
    canEdit?: boolean,
}
export const ActivityItem = React.memo((props: ActivityItemProps) => {
    const { title, tags, description, icon, info, expandContent, createdAt, clientsProps = [], onClickEdit, onClick, canEdit = true } = props
    const [expanded, setExpanded] = React.useState(false);
    const { palette } = useTheme()
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const StyleBasedOnStatus = {
        warning: { borderColor: palette.warning.main },
        danger: { borderColor: palette.error.main },
        success: { borderColor: palette.success.main },
        default: { borderColor: palette.text_light2.main }
    }
    const timeAgo = () => {
        if (!createdAt) return ""
        const duration = moment.duration(moment().diff(moment(createdAt)))
        const hours = duration.asHours();
        if (hours < 24)
            return moment(createdAt).fromNow()

    }

    return (
        <Card
            sx={{
                backgroundColor: palette.bg_primary.main,
                color: palette.text_light2.main,
                paddingX: 2,
                paddingTop: 1.5,
                borderRadius: 4,
                position: "relative",

            }}
            variant="outlined"

        >
            <Stack direction="row" alignItems="center" sx={{ position: "absolute", right: 2, top: 2 }}>
                {
                    <Typography variant="P12_Lh130" sx={{ color: "text_light2.main" }} >{timeAgo()}</Typography>
                }
                {
                    canEdit && 
                    <IconButton onClick={onClickEdit}>
                        <EditIcon size={"2rem"} color={palette.text_light2.main} />
                    </IconButton>
                }
            </Stack>
            <div onClick={onClick} style={{ cursor: "pointer" }} >
                <CardHeader
                    titleTypographyProps={{ fontSize: "18px", color: palette.text_light.main }}
                    title={title}
                    sx={{ padding: 0 }}
                    subheader={
                        <Stack marginTop={1} direction="row" gap={2} flexWrap="wrap">
                            {
                                tags?.map((tag, index) => (
                                    <MyChip
                                        key={index}
                                        label={tag.label}
                                        fontSize='12px'
                                        color={palette.text_light.main}
                                        {...StyleBasedOnStatus[tag.status ?? "default"]}

                                    />
                                ))
                            }
                        </Stack>
                    }
                />
                <Box sx={{ paddingY: 2 }}>
                    <Stack gap={1}>

                        <Typography variant="P14_Lh130" sx={{ color: palette.text_light2.main }}>
                            {description}
                        </Typography>

                        {
                            (clientsProps ?? []).map((prop, index) => (
                                <Fragment key={index}>
                                    <Divider />
                                    <Typography variant="P14_Lh130" component="p">
                                        {prop.title}
                                    </Typography>
                                    <Typography variant="P14_Lh130" component="p">
                                        {prop.value}
                                    </Typography>

                                </Fragment>
                            ))
                        }
                    </Stack>

                </Box>
            </div >

            {expandContent && <>
                <Divider />
                <CardActions disableSpacing sx={{ padding: 0 }}>
                    <IconButton aria-label="add to favorites">
                        {icon}
                    </IconButton>
                    <Typography variant="P16_Lh130" sx={{ color: palette.text_light.main }}>
                        {info}
                    </Typography>
                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ArrowRightIcon size="20px" color={palette.success.main} />
                    </ExpandMore>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    {expandContent}
                </Collapse>
            </>
            }

        </Card>
    );
})
