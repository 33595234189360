import * as React from 'react'
import Box from '@mui/material/Box'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import { IconButton, MenuItem, Stack, Typography, useTheme } from '@mui/material'
import { Link } from 'react-router-dom'

import { AgendaIcon, CloseIcon, DevisIcon, RendezVousDevisIcon, MenuIcon4, ProjetsIcon, SettingsIcon, SuiviIcon, InterventionsIcon } from '../icons'

import { useAuthentication } from '../../state'

import Menu from '@mui/material/Menu';

import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import Logout from '@mui/icons-material/Logout';



export default function MyDrawer() {
  const [state, setState] = React.useState({
    open: false
  })
  const { palette } = useTheme()
  const { currentUser } = useAuthentication()
  const toggleDrawer =
    (open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        setState(prev => ({ ...prev, open: open }))
      }

  const menuList = () => (
    <Stack
      sx={{
        maxWidth: 600,
        width: "100vw",
        boxSizing: "border-box",
        padding: 2,
        flexGrow: 1,
        overflowY: "auto"
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <Box sx={{}}>
        <Stack direction="row" alignItems="flex-start" justifyContent="space-between" marginBottom={5} >
          <img src="https://uploads-ssl.webflow.com/628919fe7f4f99ed670f7007/632b2052a7bebb16e0cbdddf_Groupe%2086.png" alt='Borne4You' style={{maxHeight: '3em', margin: '0.5em 0'}} />
          <IconButton onClick={toggleDrawer(false)}>
            <CloseIcon size="2rem" color={palette.text_light.main} />
          </IconButton>
        </Stack>

        <List>
          {MainMenu.data.map((item, index) => (
            <Link key={item.title} to={item.link} style={{ textDecoration: 'none', color: palette.text_light.main }} >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primaryTypographyProps={{ fontSize: "2rem" }} primary={item.title} />
                </ListItemButton>
              </ListItem>
            </Link>

          ))}
        </List>
        {
          false /*(currentUser?.user?.grade ?? 0) >= 8*/ && (<>
            <Typography variant="h1" sx={{ color: palette.muted_text.main }} >
              {OperationsVente.header}
            </Typography>

            <List>
              {OperationsVente.data.map((item, index) => (
                <Link key={item.title} to={item.link} style={{ textDecoration: 'none', color: palette.text_light.main }} >
                  <ListItem disablePadding >
                    <ListItemButton>
                      <ListItemIcon>
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText primaryTypographyProps={{ fontSize: "2rem" }} primary={item.title} />
                    </ListItemButton>
                  </ListItem>
                </Link>

              ))}
            </List>
          </>)
         }
      </Box>




    </Stack>
  )

  return (
    <div>
      <Button onClick={toggleDrawer(true)}>
        <MenuIcon4 size='2rem' color="white" />
      </Button>
      <SwipeableDrawer
        anchor="left"
        open={state.open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Stack sx={{ position: "relative", height: "100%", maxHeight: "100%", paddingBottom: 10, backgroundColor: palette.bg_dark_green.main }}>
          {menuList()}

          <Stack gap={2} paddingY={2}>
            <Divider sx={{ backgroundColor: palette.grey[500] }} />

            <Stack padding={2} boxSizing="border-box" width="100%" direction="row" alignItems="center" justifyContent="space-between" color={palette.text_light.main}    >
              <Stack>
                <Typography variant="P18_Lh130" sx={{ color: palette.text_light.main }}>
                  {currentUser?.user.firstName} {currentUser?.user.lastName}
                </Typography>
                <Typography variant="P18_Lh130" sx={{ color: palette.text_light2.main }}>
                  {currentUser?.user.installateursTeam.name}
                </Typography>
              </Stack>

              <AccountMenu />
            </Stack>

          </Stack>
        </Stack>

      </SwipeableDrawer>
    </div>
  )
}

const MainMenu = {
  header: "",
  data: [
    {
      title: "Interventions",
      link: "/",
      icon: <InterventionsIcon color='#fff' size='2rem' />
    },
    {
      title: "Projets",
      link: "/projets",
      icon: <ProjetsIcon size='2rem' />
    },
    {
      title: "Agenda",
      link: "/agenda",
      icon: <AgendaIcon size='2rem' />
    }
  ]
}
const OperationsVente = {
  header: "Opérations Ventes",
  data: [
    {
      title: "Devis",
      link: "/devis",
      icon: <DevisIcon size='2rem' />
    },
    {
      title: "Suivi Commercial",
      link: "/suivi_commercial",
      icon: <SuiviIcon size='2rem' />
    }
  ]
}









function AccountMenu() {
  const { signOut } = useAuthentication()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { palette } = useTheme()
  return (
    <>

      <IconButton onClick={handleClick}>
        <SettingsIcon size="2rem" color={palette.text_light2.main} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={{ bottom: 100 }}
        PaperProps={{
          elevation: 0,
          sx: {
            bgcolor: palette.bg_dark.main,
            width: "212px"
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}

      >


        <MenuItem sx={{ padding: 2 }}>
          <Stack direction="row" gap={1} alignItems='center'>
            <ListItemIcon>
              <AccountCircleRoundedIcon />
            </ListItemIcon>
            Profile
          </Stack>

        </MenuItem>

        <MenuItem sx={{ padding: 2 }} onClick={signOut} >
          <Stack direction="row" gap={1} alignItems='center'>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Se Déconnecter
          </Stack>
        </MenuItem>


      </Menu>
    </>
  );
}

