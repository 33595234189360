import { CheckBox } from '@mui/icons-material'
import { Stack, useTheme } from '@mui/material'
import React, { useMemo } from 'react'
import { ScreenHeader } from '../../util'
import { Filter, MyCalendar } from './components'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { useFormik } from 'formik'

import moment, { Moment } from 'moment'
import { useInfinitInterventions } from '../../../services_api/services'


export const Agenda = () => {
  const { palette } = useTheme()
  const { FilterEvents, filters, handleChange, errors, setFilter } = useAgenda()

  return (
    <Stack sx={{ backgroundColor: palette.bg_primary.main, minHeight: "100vh" }}>
      <ScreenHeader title='Agenda' />
      <Filter filters={filters} setFilter={setFilter} />
      <MyCalendar events={FilterEvents()} />
    </Stack>
  )
}

const useAgenda = () => {
  const {
    results: interventions,
    isLoading,
    isFetchingNextPage: loading_more,
    fetchNextPage: loadMore,
    hasNextPage: can_load_more
  } = useInfinitInterventions(100)// 100 == page size ,it can be changed

  const { values: filters, handleChange, errors, setFieldValue: setFilter } = useFormik({
    initialValues: {
      devis: true,
      installation: true,
    },
    onSubmit: (values: any) => {
    }
  })
  const events: EventType[] = useMemo(() => {
    if (!interventions) return []
    return interventions.map(item => ({
      id: item.id,
      title: `RDV ${item.interventionType}`,
      start: moment(item.scheduledStartDatetime).toDate(),
      end: moment(item.scheduledEndDatetime).toDate(),
      projectId:item.projetId
    }))

  }, [interventions])

  const FilterEvents = () => {
    if (filters.devis && filters.installation) return events
    return events.filter(event =>
      (event.title === "RDV devis" && filters.devis)
      ||
      (event.title === "RDV installation" && filters.installation)
    )
  }
  return { FilterEvents, filters, handleChange, errors, setFilter }

}

export type EventType = {
  id: number
  title: string
  start: Date
  end: Date
  projectId: number
}
