
import axios, { AxiosError, AxiosResponse } from "axios"
import toast from "react-hot-toast"
import { BACKEND_BASE_URL } from "../../consts"
import { LoginRequest, LoginResponse } from "../@types"




export class AuthService {

   
    static async SignIN(reqBody: LoginRequest): Promise<LoginResponse> {
        const url = `${BACKEND_BASE_URL}/techniciensApi/v1/auth/login`
        try {
            const data:LoginResponse = (await axios.post(url, reqBody)).data
            localStorage.setItem("user", JSON.stringify(data))
            console.log(data.accessToken);
            
            return data
        } catch (err: any) {
            toast.error(err.message)
            throw new Error(...err);
        }
    }
    static async SignOut(): Promise<void> {
        try {
            localStorage.removeItem("user")
        } catch (err: any) {
            toast.error(err.message)
            throw new Error(...err);
        }
    }

}











