import { Divider, Stack, Typography, useTheme } from '@mui/material'
import React, { Fragment } from 'react'
import { Customer } from '../../../../../../services_api'
import parsePhoneNumber from 'libphonenumber-js'

type ClientInfoPanelProps = {
  client?: Customer
}
export const ClientInfoPanel = (props: ClientInfoPanelProps) => {
  const { client } = props
  const { palette } = useTheme()
  return (
    <Stack
      sx={
        {
          backgroundColor: palette.bg_dark_green.main,
          color: palette.muted_text.main,
          flexGrow: 1,
          padding: 2,
        }
      }
      gap={2}
    >
      <Stack paddingX={0.5} direction='row' justifyContent='space-between' alignItems='center' >
        <Typography variant="P14_Lh130" component="p">
          Nom
        </Typography>
        <Typography variant="P14_Lh130" component="p">
          {client?.lastName ?? "-"}
        </Typography>
      </Stack>
      <Divider />
      <Stack paddingX={0.5} direction='row' justifyContent='space-between' alignItems='center' >
        <Typography variant="P14_Lh130" component="p">
          Prénom
        </Typography>
        <Typography variant="P14_Lh130" component="p">
          {client?.firstName ?? "-"}
        </Typography>
      </Stack>
      <Divider />
      <Stack paddingX={0.5} direction='row' justifyContent='space-between' alignItems='center' >
        <Typography variant="P14_Lh130" component="p">
          E-mail
        </Typography>
        <Typography variant="P14_Lh130" component="p">
          {client?.email ?? "-"}
        </Typography>
      </Stack>
      <Divider />
      <Stack paddingX={0.5} direction='row' justifyContent='space-between' alignItems='center' >
        <Typography variant="P14_Lh130" component="p">
          Numéro de téléphone
        </Typography>
        <Typography variant="P14_Lh130" component="a">
          {
            client?.phoneNumber ? (
              <a href={parsePhoneNumber(client.phoneNumber, 'FR')?.getURI()} style={{color: 'unset'}}>
                {parsePhoneNumber(client.phoneNumber, 'FR')?.formatNational() ?? '-'}
              </a>
            ) : '-'
          }
        </Typography>
      </Stack>

      {client?.properties?.map((prop, index) => (
        <Fragment key={index}>
          <Stack paddingX={0.5} direction='row' justifyContent='space-between' alignItems='center' >
            <Typography variant="P14_Lh130" component="p">
              {prop.title}
            </Typography>
            <Typography variant="P14_Lh130" component="p">
              {prop.value}
            </Typography>
          </Stack>
          <Divider />
        </Fragment>
      ))}
    </Stack >
  )
}
