import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BarConfig, Column } from '@ant-design/plots';
import { ChartWraper } from './ChartWraper';
import { Button, Divider, Stack, Typography, useTheme } from '@mui/material';

import { MyProgressBar } from '../../../../util';

export const BarChart = () => {
    const { palette } = useTheme()
    const data = [
        {
            type: 'Mar',
            value: 10,
        },
        {
            type: 'Mer',
            value: 20,
        },
        {
            type: 'Jeu',
            value: 40,
        },
        {
            type: 'Ven',
            value: 30,
        },
        {
            type: 'Sam',
            value: 20,
        },
        {
            type: 'Hier',
            value: 10,
        },
        {
            type: "AuJourd'hui",
            value: 5,
        },
    ];
    const maxValue = Math.max(...data.map(d => d.value))
    const highLighted = data.filter(d => d.value === maxValue)[0]


    const config: BarConfig = {
        data,
        xField: 'type',
        yField: 'value',
        seriesField: '',
        color: ({ type }) => {

            if (type !== highLighted.type) {
                return palette.gray.main;
            }

            return palette.primary.main;
        },
        label: false,
        legend: false,
        xAxis: false,
        yAxis: false
    };
    return (
        <ChartWraper>
            <Stack padding={2} gap={3} >
                <Stack height={200} paddingRight={8} position="relative">
                    <Column {...config} />
                    <Typography variant="P14_Lh130" sx={{
                        color: palette.muted_text.main,
                        position: "absolute",
                        top: 8, right: 8,
                    }}
                    >
                        Les 7 jours derniers
                    </Typography>
                </Stack>
                <Stack gap={1}   >
                    <Typography variant="h2" sx={{ color: palette.text_light2.main }}>
                        2.7 K
                    </Typography>
                    <Typography variant="P12_Lh130" style={{ color: palette.muted_text.main }}>
                        Avg Sessions
                    </Typography>
                    <Typography variant="P12_Lh130" style={{ color: palette.muted_text.main }}>
                        <span style={{ color: palette.success.main, marginRight: "0.5rem" }}>
                            +5.12%
                        </span>
                        vs Les 7 jours derniers
                    </Typography>
                    <Button color="primary" variant="contained" >
                        <Typography variant="P14_Lh130">
                            Voir les détails
                        </Typography>
                    </Button>
                </Stack>
                <Divider sx={{ backgroundColor: palette.muted_text.main }} />
                <Stack gap={1} color={palette.muted_text.main} width="100%">
                    <Stack direction='row' gap={2} width="100%">
                        <Stack gap={0.5} flexGrow={1}>
                            <Typography variant="P12_Lh130" >
                                But: 100000 €
                            </Typography>
                            <MyProgressBar color={palette.primary.main} bgColor={palette.tr_primary.main} value={40} />
                        </Stack>
                        <Stack gap={0.5} flexGrow={1}>
                            <Typography variant="P12_Lh130" >
                                Users: 100K
                            </Typography>
                            <MyProgressBar color={palette.warning.main} bgColor={palette.tr_warning.main} value={65} />
                        </Stack>
                    </Stack>
                    <Stack direction='row' gap={2} width="100%">
                        <Stack gap={0.5} flexGrow={1}>
                            <Typography variant="P12_Lh130" >
                                Retention: 90 %
                            </Typography>
                            <MyProgressBar color={palette.error.main} bgColor={palette.tr_error.main} value={90} />
                        </Stack>
                        <Stack gap={0.5} flexGrow={1}>
                            <Typography variant="P12_Lh130" >
                                Duration: 1 ans
                            </Typography>
                            <MyProgressBar color={palette.success.main} bgColor={palette.tr_success.main} value={85} />
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>

        </ChartWraper>
    )
};



