import { Stack, useTheme } from '@mui/material'
import moment from 'moment'
import React, { Fragment, useMemo, useState } from 'react'
import { getDateTimeString } from '../../../../../../helpers'
import { Projet } from '../../../../../../services_api'
import { AttachmentIcon, NoteIcon } from '../../../../../icons'
import { MultiModalProvider } from '../../../../../navigation'
import { Actions } from '../../Actions'
import { ActivityItem } from '../../ActivityItem'
import { ImagesPicker } from '../../ImagesPicker'
import { CreationDevisModal, EtudeFaisabilteModal, CreationRDVModal, EnvoiePayment, CompteRenduInterventionModal } from './modals'
import { ViewDeviModal } from './modals/viewDevisModal'

type ActivityPanelProps = {
  projet: Projet
}




export const ActivityPanel = (props: ActivityPanelProps) => {
  const { projet } = props
  const { palette } = useTheme()
  const { modal, data, isReadOnly, closeModal, openModal } = useActivityPanel()



  const activitiesElemnts = useMemo(() => [
    /** etude */
    ...(projet?.etudeFaisabilites ?? []).map((item, index) => ({
      datetime: item.createdAt,
      element:
        <Fragment key={`etude${index}`}>
          <ActivityItem
            createdAt={item.createdAt}
            title='Étude de faisabilité'
            tags={[
              { label: `le ${getDateTimeString(item.createdAt)}` },
              (
                item.isFaisable ?
                  { label: `Faisable`, status: "success" }
                  :
                  { label: `Non faisable`, status: "warning" }
              )
            ]}
            description={item.text ?? ""}
            icon={<AttachmentIcon size={"20px"} color={palette.text_light.main} />}
            info={`${item.attachedFiles?.length}`}
            expandContent={item.attachedFiles?.length ?
              <ImagesPicker readOnly attachedFiles={item.attachedFiles} /> : false
            }
            onClickEdit={openModal("etude_faisabilite", item, false)}
            onClick={openModal("etude_faisabilite", item, true)}
          />
        </Fragment>
    })),
    /** devis */
    ...(projet?.devis ?? []).map((item, index) => ({
      datetime: item.createdAt,
      element:
        <ActivityItem
          createdAt={item.createdAt}
          key={`devis${index}`}
          title='Devis généré'
          tags={[
            { label: `le ${getDateTimeString(item.createdAt)}` },
            (
              item.isAccepted ?
                { label: `Approuvé`, status: "success" }
                :
                (
                  item.isRefused ?
                    { label: `Refusé`, status: "danger" }
                    :
                    { label: `En attente` }
                )
            )
          ]}
          description={""}
          icon={< AttachmentIcon size={"20px"} color={palette.text_light.main} />}
          onClickEdit={openModal("creaction_devis", item, false)}
          onClick={openModal("view_devis", item, true)}
          canEdit={false}
        />
    })),
    /** payment */
    ...(projet?.paymentRequests ?? []).map((item, index) => ({
      datetime: item.createdAt,
      element:
        <ActivityItem
          key={`facture${index}`}
          createdAt={item.createdAt}
          title='Envoi de paiement'
          tags={[
            { label: `Le ${getDateTimeString(item.createdAt)}` },
            (
              item.isPaid ?
                { label: `Payé`, status: "success" }
                :
                { label: `Attente Paiement`, status: "danger" }
            )
          ]}
          description={""}
          icon={<NoteIcon size={"20px"} color={palette.text_light.main} />}
          onClickEdit={openModal("envoie_payment", item, false)}
          onClick={openModal("envoie_payment", item, true)}
          canEdit={false}
        />
    })),

    ...(projet?.interventionRdvs ?? []).map((item, index) => ({
      datetime: item.scheduledStartDatetime,
      element:
        <Fragment key={`interv${index}`}>
          <ActivityItem
            createdAt={item.scheduledStartDatetime}
            title={`Rendez-vous ${item.interventionType}`}
            tags={[
              { label: `Le ${getDateTimeString(item.scheduledStartDatetime)}` },
              (
                item.isCancelled ?
                  { label: `Annulé`, status: "danger" }
                  :
                  item.isDone ?
                    { label: `Terminé`, status: "success" }
                    :
                    { label: `En attente`, status: "warning" }
              )
            ]}
            description={item.text ?? ""}
            clientsProps={item.interventionType === 'devis' ? (projet?.customer?.properties) : undefined}
            icon={<NoteIcon size={"20px"} color={palette.text_light.main} />}
            onClickEdit={openModal("creation_rdv", item, false)}
            onClick={openModal("creation_rdv", item, true)}
          />
        </Fragment>
    })),
    ...(projet?.interventionCompteRendus ?? []).map((item, index) => ({
      datetime: item.createdAt,
      element: <Fragment key={`compete-rendu${index}`}>
        <ActivityItem
          createdAt={item.createdAt}
          title={`Compte-rendu intervention`}
          tags={[
            { label: `Le ${getDateTimeString(item.createdAt)}` },
            /*(
              item.???? ?
                { label: `Payé`, status: "success" }
                :
                { label: `Attente Paiement`, status: "danger" }
            )*/
          ]}
          description={item.text ?? ""}
          icon={<AttachmentIcon size={"20px"} color={palette.text_light.main} />}
          info={`${item.attachedFiles?.length}`}
          expandContent={item.attachedFiles?.length ?
            <ImagesPicker readOnly attachedFiles={item.attachedFiles} /> : false
          }
          onClickEdit={openModal("compte_rendu_intervention", item, false)}
          onClick={openModal("compte_rendu_intervention", item, true)}
        />
      </Fragment>
    })),


  ].sort((a, b) => moment(b.datetime).diff(a.datetime, 'seconds')).map(({ element }) => element), [projet])


  if (!projet) return <></>

  return (
    <Stack sx={{ backgroundColor: palette.bg_dark_green.main }} flexGrow={1}   >
      <MultiModalProvider>
        <Actions projet={projet} />
      </MultiModalProvider>

      <Stack paddingTop={2} gap={2} paddingBottom={8} paddingX={2}>
        {activitiesElemnts}
      </Stack>
      {/** modals to edit or veiw details when activity is opened */}
      {modal === "etude_faisabilite" &&
        <EtudeFaisabilteModal
          projetId={projet?.id}
          action={isReadOnly ? "read" : "update"}
          isOpen={true}
          readonly={isReadOnly}
          onclose={closeModal}
          data={data}
        />
      }
      {modal === "creaction_devis" &&
        <CreationDevisModal
          projetId={projet?.id}
          action={isReadOnly ? "read" : "update"}
          isOpen={true}
          readonly={isReadOnly}
          onclose={closeModal}
          data={data}
          projet={projet}
        />
      }
      {modal === "view_devis" &&
        <ViewDeviModal
          projetId={projet?.id}
          action={"read"}
          isOpen={true}
          readonly={isReadOnly}
          onclose={closeModal}
          data={data}
        />
      }
      {modal === "envoie_payment" &&
        <EnvoiePayment
          projetId={projet?.id}
          action={isReadOnly ? "read" : "update"}
          isOpen={true}
          readonly={isReadOnly}
          onclose={closeModal}
          data={data}
          devis={projet.devis ?? []}
        />
      }
      {modal === "creation_rdv" &&
        <CreationRDVModal
          projetId={projet?.id}
          action={isReadOnly ? "read" : "update"}
          isOpen={true}
          readonly={isReadOnly}
          onclose={closeModal}
          data={data}
        />
      }
      {modal === "compte_rendu_intervention" &&
        <CompteRenduInterventionModal
          projetId={projet?.id}
          action={isReadOnly ? "read" : "update"}
          isOpen={true}
          readonly={isReadOnly}
          onclose={closeModal}
          data={data}
        />
      }



    </Stack>
  )
}
type modalType = "etude_faisabilite" | "creaction_devis" | "view_devis" | "envoie_payment" | "creation_rdv" | "compte_rendu_intervention" | null
type modalSatteType = {
  modal: modalType
  data: any,
  readOnly: boolean
}

const useActivityPanel = () => {
  const [modalstate, setModalstate] = useState<modalSatteType>({
    modal: null,
    data: null,
    readOnly: false
  })

  const isOpen = modalstate.modal !== null
  const isReadOnly = modalstate.readOnly
  const closeModal = () => {
    setModalstate(prev => ({ ...prev, data: null, modal: null }))
  }
  const openModal = (modal: modalType, data: any, readOnly: boolean) => {
    return () => setModalstate(prev => ({ ...prev, modal, data, readOnly }))
  }
  return { modal: modalstate.modal, data: modalstate.data, isOpen, isReadOnly, closeModal, openModal }

}