import { Box, Divider, ListItemButton, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { getProjectBorderSyle, getProjectStyles } from '../../../../helpers'
import { ProjectStatusType } from '../../../../services_api'
import { MyChip, MyProgressBar, ScreenHeader } from '../../../util'


type ProjectItemProps = {
    projectId: number
    idString: string
    statusColor: ProjectStatusType
    date: string
    agentName: string
    adresse: string
    agentType: "Particulier" | " Professionel"
    task: string
    progress: number
}
export const ProjectItem = (props: ProjectItemProps) => {
    const { projectId, idString, date, statusColor, agentName, adresse, agentType, task, progress } = props
    const { palette } = useTheme()
    return (
        <Link to={`/projets/${projectId}`} style={{ textDecoration: "none" }} >
            <ListItemButton >
                <Stack gap={1} paddingY={1} width={"100%"} >
                    <Stack direction="row" gap={2} alignItems="center" justifyContent="space-between" color={palette.text_light2.main}>
                        <Stack gap={0.2}>
                            <Typography variant="h2" >
                                #{idString}
                            </Typography>
                            <Typography variant="P12_Lh130" sx={{ color: palette.muted_text.main }}>
                                Crée le {date}
                            </Typography>
                        </Stack>
                        <Stack gap={0.2} alignItems="flex-start">
                            <Typography variant="h4" >
                                {agentName}
                            </Typography>
                            <Typography variant="P12_Lh130" sx={{ color: palette.muted_text.main }}>
                                {adresse}
                            </Typography>
                            <MyChip
                                label={agentType}
                                color={palette.text_light2.main}
                                borderColor={palette.muted_text.main}

                                fontSize="12px"
                                sx={{ padding: "2px 10px" }}
                            />
                        </Stack>
                        <Stack gap={1}>
                            <MyChip
                                label={task}
                                color={palette.text_light2.main}
                                {...getProjectBorderSyle(statusColor, palette)}
                                fontSize="10px"
                                sx={{ padding: "2px 8px" }}
                            />
                            <MyProgressBar value={progress} {...getProjectStyles(statusColor, palette)} />
                        </Stack>
                    </Stack>
                </Stack>
            </ListItemButton>

        </Link>

    )
}
