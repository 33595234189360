import { CreateDeviModal } from '@clean/ProjetScreen/modals/CreateDeviModal'
import { SendPaymentRequestModal } from '@clean/ProjetScreen/modals/SendPaymentRequestModal'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'
import { Projet } from '../../../../services_api'
import { useAuthentication } from '../../../../state'
import { EtudeFaisabilityIcon, DevisIcon, FactureIcon, RendezVousDevisIcon, CompteRenduIcon } from '../../../icons'
import { useMultiModal } from '../../../navigation'
import { ContactItem } from './ContactItem'
import { CompteRenduInterventionModal, CreationDevisModal, EnvoiePayment, EtudeFaisabilteModal, CreationRDVModal } from './panels/activity-panel/modals'



type ActionsProps = {
    projet: Projet,
}
export const Actions = (props: ActionsProps) => {
    const { projet } = props
    const { palette } = useTheme()
    const { currentUser } = useAuthentication()
    const { modalKey, setModalKey } = useMultiModal()
    const getModals = () => ({
        'createEtudeFaisabilite': <EtudeFaisabilteModal projetId={projet.id} action='create' onclose={() => setModalKey(null)} isOpen={true} />,
        'createDevisRdv': <CreationRDVModal action='create' projetId={projet.id} onclose={() => setModalKey(null)} isOpen={true} />,
        'createDevi': <CreateDeviModal
            onClose={() => setModalKey(null)}
            projet={projet}
        />,
        'createPaymentRequest': <SendPaymentRequestModal
            onClose={() => setModalKey(null)}
            projet={projet}
            availableDevis={projet.devis?.sort((a, b) => b?.id-a?.id) ?? []}
        />,
        'createInterventionCompteRendu': <CompteRenduInterventionModal projetId={projet.id} action='create' onclose={() => setModalKey(null)} isOpen={true} />,
    })
    return (
        <React.Fragment>
            <Stack direction="row" padding={2} gap={1} justifyContent="space-between" flexWrap="nowrap" overflow="auto" >
                {
                    getActions(palette, currentUser?.user?.grade).map(item => (
                        <ContactItem
                            key={item.key}
                            icon={item.icon}
                            title={item.title}
                            size="4rem"
                            borderColor={item.key === modalKey ? palette.text_light.main : palette.muted_text.main}
                            onClick={() => setModalKey(item.key)}
                        />
                    ))
                }

            </Stack>
            <Box sx={{ position: "relative" }}>
                <React.Fragment key={modalKey}>
                    {modalKey != null && modalKey in getModals() ? (getModals() as any)[modalKey] : null}
                </React.Fragment>
            </Box>
        </React.Fragment>

    )
}

const getActions = (palette: any, grade: number | null | undefined) => {
    if((grade ?? 0) >= 8) {
        return [
            {
                icon: <EtudeFaisabilityIcon size="2rem" color={palette.text_light.main} />,
                title: "Créer étude de faisabilité",
                key: 'createEtudeFaisabilite',
            },
            {
                icon: <RendezVousDevisIcon size="2rem" color={palette.text_light.main} />,
                title: "Créer RDV",
                key: 'createDevisRdv',
            },
            {
                icon: <DevisIcon size="2rem" color={palette.text_light.main} />,
                title: "Créer devis",
                key: 'createDevi',
            },
            {
                icon: <FactureIcon size="2rem" color={palette.text_light.main} />,
                title: "Créer demande de paiement",
                key: 'createPaymentRequest',
            },
            {
                icon: <CompteRenduIcon size="2rem" color={palette.text_light.main} />,
                title: "Créer compte-rendu d'intervention",
                key: 'createInterventionCompteRendu',
            },
        ]
    } else {
        return [
            {
                icon: <EtudeFaisabilityIcon size="2rem" color={palette.text_light.main} />,
                title: "Etude de faisabilité",
                key: 'createEtudeFaisabilite',
            },
            {
                icon: <RendezVousDevisIcon size="2rem" color={palette.text_light.main} />,
                title: "Créer RDV",
                key: 'createDevisRdv',
            },
            {
                icon: <CompteRenduIcon size="2rem" color={palette.text_light.main} />,
                title: "Compte-rendu intervention",
                key: 'createInterventionCompteRendu',
            },
        ]
    }
}