export const projectsKeys = {
  for_many: "projets",
};
export const etudeFaisabilityKeys = {
  for_many: "etudes",
};
export const devisKeys = {
  for_many: "devis",
};
export const envoiePaiementKeys = {
  for_many: "payments",
};
export const compteRenduKeys = {
  for_many: "compte-rendus",
};
export const interventionKeys = {
  for_many: "interventions",
};
export const produitKeys = {
  for_many: "produits",
};
