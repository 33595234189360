import React from 'react'
import { Stack, useTheme } from '@mui/material';
import { ContactItem } from './ContactItem';
import { ChatIcon, LocationIcon, MailIcon, PhoneCallIcon } from '../../../icons';
import { Projet } from '../../../../services_api';
import { openMail, getMapLink } from '../../../../helpers';
import { parsePhoneNumber } from 'libphonenumber-js';

type ContactLinksProps = {
    projet: Projet
}
export const ContactLinks = (props: ContactLinksProps) => {
    const { projet } = props

    const { palette } = useTheme()

    return (
        <Stack direction="row" gap={1} paddingBottom={2} justifyContent="space-between" flexWrap="nowrap" overflow="auto" >
            <ContactItem
                icon={<LocationIcon size="2.5rem" color={palette.success.main} />}
                title="Itinéraires"
                borderColor={palette.success.main}
                href={getMapLink(projet.addressLine1, projet.addressCity, projet.addressPostalCode)}
            />
            <ContactItem
                icon={<PhoneCallIcon size="2.5rem" color={palette.success.main} />}
                title="Appel"
                borderColor={palette.success.main}
                href={projet.customer?.phoneNumber ? parsePhoneNumber(projet.customer?.phoneNumber, 'FR')?.getURI() : undefined}
            />
            <ContactItem
                icon={<ChatIcon size="2.5rem" color={palette.success.main} />}
                title="SMS"
                borderColor={palette.success.main}
                href={projet.customer?.phoneNumber ? 'sms:'+parsePhoneNumber(projet.customer?.phoneNumber, 'FR')?.format('E.164') : undefined}
            />
            <ContactItem
                icon={<MailIcon size="2.5rem" color={palette.success.main} />}
                title="Email"
                borderColor={palette.success.main}
                href={`mailto:${projet.customer?.email}?subject=sujet&body=Bonjour ${projet.customer?.lastName}`}
            />
        </Stack>
    )
}

