import { useInfinitFetche } from "../../hooks";
import {
  CreateInterventionRdvRequest,
  CreateInterventionRdvResponse,
  InterventionRdv,
  UpdateInterventionRdvRequest,
  UpdateInterventionRdvResponse,
} from "../@types";
import { interventionKeys } from "../keys";
import { GenricService } from "./geniric.service";

export class InterventionService {
  static async fetchAll(): Promise<InterventionRdv> {
    const route = `/techniciensApi/v1/interventionRdvs`;
    return GenricService.fetch<InterventionRdv>(route, {});
  }

  static async fetchOne(id: number): Promise<InterventionRdv> {
    const route = `/techniciensApi/v1/interventionRdvs`;
    return GenricService.fetchOneById<InterventionRdv>(route, id);
  }

  static async createOne(
    data: CreateInterventionRdvRequest
  ): Promise<CreateInterventionRdvResponse> {
    const route = `/techniciensApi/v1/interventionRdvs`;
    return GenricService.PostOne<
      CreateInterventionRdvRequest,
      CreateInterventionRdvResponse
    >(route, data);
  }

  static async updateOne(
    data: UpdateInterventionRdvRequest
  ): Promise<UpdateInterventionRdvResponse> {
    const route = `/techniciensApi/v1/interventionRdvs/${data.id}`;
    return GenricService.PatchOne<
      UpdateInterventionRdvRequest,
      UpdateInterventionRdvResponse
    >(route, data);
  }
}

export function useInfinitInterventions(pagesize: number) {
  const PAGESIZE = pagesize;
  return useInfinitFetche<InterventionRdv>(
    interventionKeys.for_many,
    `/techniciensApi/v1/interventionRdvs`,
    {},
    PAGESIZE
  );
}
