import * as React from 'react';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { IconButton, useTheme } from '@mui/material';
import { CircleCloseIcon } from '../../../icons';
import { PickerItemWraper } from './ImagesPicker';
import { JsxElement } from 'typescript';

const emails = ['username@gmail.com', 'user02@gmail.com'];

export interface SimpleDialogProps {
    open: boolean;
    selectedValue: string;
    onClose: (value: string) => void;
    image: string
}

function SimpleDialog(props: SimpleDialogProps) {
    const { onClose, selectedValue, open, image } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    return (
        <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
            <img style={{ width: "100%", height: "100%" }} src={image} alt="img" />
        </Dialog>
    );
}

export function ImagePopUp({ image, remover }: { image: string, remover: React.ReactNode }) {
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState(emails[1]);
    const { palette } = useTheme()

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value: string) => {
        setOpen(false);
        setSelectedValue(value);
    };
    console.log({ image })
    return (
        <div>
            <PickerItemWraper onClick={handleClickOpen}>
                <img style={{ width: "100%", height: "100%" }} src={image} alt="img" />
                {remover}
            </PickerItemWraper>
            <SimpleDialog
                image={image}
                selectedValue={selectedValue}
                open={open}
                onClose={handleClose}
            />
        </div>
    );
}
