import { useInfinitFetche } from "../../hooks";
import { Product } from "../@types";
import { produitKeys } from "../keys";

export function useInfinitProducts(search?: string) {
  const PAGESIZE = 10;
  const {
    results: products,
    isLoading,
    isFetchingNextPage: loading_more,
    fetchNextPage: loadMore,
    hasNextPage: can_load_more,
  } = useInfinitFetche<Product>(
    produitKeys.for_many,
    "/techniciensApi/v1/products",
    { search },
    PAGESIZE
  );

  return { products, isLoading, loading_more, can_load_more, loadMore };
}
