import { Box, useTheme } from '@mui/material'
import React from 'react'

export const ChartWraper = ({ children }: any) => {
    const { palette } = useTheme()
    return (
        <Box sx={{ borderRadius: 4, backgroundColor: palette.bg_primary.main }}>
            {children}
        </Box>
    )
}
