import React from 'react'
import { CheckBox } from '@mui/icons-material'
import { Checkbox, FormControlLabel, Stack, Typography, useTheme } from '@mui/material'
import { useFormik } from 'formik'

type FilterProps = {
    filters: {
        devis: boolean,
        installation: boolean,
    },
    setFilter: (name: string, value: any) => void
}
export const Filter = (props: FilterProps) => {
    const { filters, setFilter } = props
    const { palette } = useTheme()

    return (
        <div style={{ backgroundColor: palette.bg_dark_green.main }}>
            <Stack
                gap={3}
                direction="row"
                overflow="auto"
                alignItems="center"
                sx={{ paddingX: 3, paddingY: 1 }}
            >
                <Typography variant="P14_Lh130" sx={{ color: palette.text_light.main }} >
                    FILTRE
                </Typography>

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={filters.devis}
                            onChange={(e: any, checked) => setFilter("devis", checked)}
                            color="warning"
                        />
                    }

                    label={
                        <Typography variant="P14_Lh130" sx={{ color: palette.text_light2.main }} >
                            RDV devis
                        </Typography>
                    }

                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={filters.installation}
                            onChange={(e: any, checked) => setFilter("installation", checked)}
                            color="success"
                        />
                    }
                    label={
                        <Typography variant="P14_Lh130" sx={{ color: palette.text_light2.main }} >
                            RDV install
                        </Typography>
                    }

                />

            </Stack>
        </div>

    )
}
