import {
  CreatePaymentRequest,
  CreatePaymentResponse,
  EnvoiePaymentType,
  UpdatePaymentRequest,
  UpdatePaymentResponse,
} from "../@types";
import { GenricService } from "./geniric.service";

const BASE_ROUT = `/techniciensApi/v1/paymentRequests`;

export class EnvoiePaymentService {
  static async fetchAll(): Promise<EnvoiePaymentType> {
    const route = BASE_ROUT;
    return GenricService.fetch<EnvoiePaymentType>(route, {});
  }

  static async fetchOne(id: number): Promise<EnvoiePaymentType> {
    const route = BASE_ROUT;
    return GenricService.fetchOneById<EnvoiePaymentType>(route, id);
  }

  static async createOne(
    data: CreatePaymentRequest
  ): Promise<CreatePaymentResponse> {
    const route = BASE_ROUT;
    return GenricService.PostOne<CreatePaymentRequest, CreatePaymentResponse>(
      route,
      data
    );
  }

  static async updateOne(
    data: UpdatePaymentRequest
  ): Promise<UpdatePaymentResponse> {
    const route = `${BASE_ROUT}/${data.id}`;
    return GenricService.PatchOne<UpdatePaymentRequest, UpdatePaymentResponse>(
      route,
      data
    );
  }
}
