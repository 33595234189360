import { Stack, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom';
import { ProjectService, useProject } from '../../../services_api/services';

import { Tabulation } from '../../navigation';
import { LoadingBlock, MyChip, MyProgressBar, ScreenHeader } from '../../util'
import { ContactItem, ContactLinks } from './components';
import { ActivityPanel, ClientInfoPanel } from './components/panels';
import { useQuery } from '@tanstack/react-query'
import { ProjectStatusType } from '../../../services_api';
import { getProjectBorderSyle, getProjectStyles } from '../../../helpers';
import { projectsKeys } from '../../../services_api/keys';





export const Projet = () => {
    const params = useParams();
    const projectId = params.idProjet
    const { data: project, isLoading, isError, refetch } = useProject(Number(projectId))
    const { palette } = useTheme()


    return (
        <Stack sx={{ backgroundColor: palette.bg_dark.main, minHeight: "100vh" }}>
            <ScreenHeader title={project?.idString ? `#${project?.idString}` : ""} />
            {isLoading &&
                <LoadingBlock />
            }
            {project &&
                <>
                    <Stack paddingX={3} gap={2}>

                        <Stack color={palette.text_light2.main} >
                            <Typography variant="P20_Lh130" >
                                {`
                                ${project?.customer?.firstName} 
                                ${project?.customer?.lastName} - 
                                ${project?.addressCity} - 
                                ${project?.addressPostalCode} 
                                `}
                            </Typography>
                            <Typography variant="P20_Lh130" >
                                Description rapide du projet : {project?.title}
                            </Typography>

                        </Stack>

                        <Stack gap={2} >
                            <div style={{ alignSelf: "flex-start" }}>
                                <MyChip
                                    label={`Statut: ${project?.statusText}`}
                                    color={palette.text_light2.main}
                                    {...getProjectBorderSyle(project.statusColor, palette)}
                                    fontSize="14px"
                                    sx={{ padding: "2px 16px" }}
                                />
                            </div>
                            <MyProgressBar value={(project?.progression || 0) * 100} {...getProjectStyles(project.statusColor, palette)} />
                        </Stack>

                        <ContactLinks projet={project} />

                    </Stack>
                    <Stack flexGrow={1} >
                        <Tabulation

                            tabs={[
                                <p>Activité</p>,
                                <p>Informations client</p>
                            ]}
                            panels={[
                                <ActivityPanel
                                    projet={project}
                                />,
                                <ClientInfoPanel client={project.customer} />
                            ]}
                        />
                    </Stack>
                </>
            }
        </Stack>
    )
}





