import React, { FC } from 'react'

type MyChipProps = {
    label: string,
    color: string,
    borderColor:string,
    fontSize: string,
    sx?:any
}
export const MyChip: React.FC<MyChipProps> = (props) => {
    const { label, color,borderColor, fontSize,sx={padding:"2px 4px"} } = props
    return (
        <span style={{ border: "1px solid", color:color  , borderColor: borderColor, fontSize: fontSize, borderRadius: "6px", ...sx }}>
            {label}
        </span>
    )
}
