import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Area } from '@ant-design/plots';
import { Box, Stack, Typography, useTheme} from '@mui/material';
import { ChartWraper } from './ChartWraper';
import { BoxIcon, EuroIcon, ShopingIcon } from '../../../../icons';
import { flexbox } from '@mui/system';

export const OrderRecieved = () => {
    const { palette } = useTheme()
    const [data, setData] = useState([]);

    useEffect(() => {
        asyncFetch();
    }, []);

    const asyncFetch = () => {
        fetch('https://gw.alipayobjects.com/os/bmw-prod/1d565782-dde4-4bb6-8946-ea6a38ccf184.json')
            .then((response) => response.json())
            .then((json) => setData(json))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    };
    const config = {
        data,
        xField: 'Date',
        color: palette.warning.main,
        yField: 'scales',
        xAxis: {
            tickCount: 0,
            visible: false
        },
        yAxis: {
            tickCount: 0,
            visible: false
        },

        areaStyle: () => {
            return {
                fill: `l(270) 0:${palette.bg_primary.main} 0.5:${palette.warning.main} 1:${palette.warning.main}`,
            };
        },
    };

    return (
        <ChartWraper>
            <Stack height={300} >
                <Stack padding={2} height={150} gap={2.5}>
                    <Box sx={{
                        display: "flex",
                        height: 40, width: 40,
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "100%",
                        backgroundColor: palette.tr_warning.main
                    }}>
                        <BoxIcon size='20px' color={palette.warning.main} />
                    </Box>

                    <Typography variant="h2" sx={{  color: palette.text_light2.main }}>
                        38.4 K
                    </Typography>

                    <Typography variant="P14_Lh130" sx={{  color: palette.muted_text.main }}>
                        Le chiffre d'affaire généré ce mois
                    </Typography>

                </Stack>
                <Stack height={150}>
                    <Area {...config} />
                </Stack>

            </Stack>
        </ChartWraper>

    )
};

