import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Pie, PieConfig } from '@ant-design/plots';
import { Box, Stack,Typography,useTheme } from '@mui/material';
import { ChartWraper } from './ChartWraper';
import { DevisIcon, EuroIcon, GoingUpIcon, ShopingIcon } from '../../../../icons';
import { flexbox } from '@mui/system';

export const EtudeProjets = () => {
    const { palette } = useTheme()
    const data = [
        {
            type: 'Rendez-vous devis',
            value: 40,
        },
        {
            type: 'Installation réussite',
            value: 20,
        },
        {
            type: 'Factures envoyées',
            value: 6,
        },

    ];



    const config: PieConfig = {
        appendPadding: 10,
        data,
        angleField: 'value',
        colorField: 'type',
        radius: 1,
        innerRadius: 0.6,
        label: {
            type: 'inner',
            
            offset: '-50%',

            content: '{value}',
            style: {
                textAlign: 'center',
                fontSize: 14,
            },
        },
        legend: false,
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                content: '',
            },
        },
    };


    return (
        <ChartWraper>
            <Stack height={500} gap={2} >
                <Stack padding={2} direction="row" justifyContent="space-between" alignItems="center">

                    <Typography variant='h3' sx={{ color: palette.text_light.main }}>
                        Etudes des Projets
                    </Typography>
                    <Typography  variant='P14_Lh130' sx={{  color: palette.muted_text.main }}>
                        Ce mois
                    </Typography>

                </Stack>
                <Stack height={300}>
                    <Pie  {...config} />;
                </Stack>
                <Stack padding={2} gap={1}>
                    {
                        data.map((item, index: number) => (
                            <PieLegend {...item} key={index} />
                        ))
                    }
                </Stack>
            </Stack>
        </ChartWraper>

    )
};

type PieLegendProps = {
    type: string
    value: number
}
const PieLegend = (props: PieLegendProps) => {
    const { palette } = useTheme()
    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" color={palette.text_light2.main} >
            <Stack direction="row" gap={0.5} alignItems="center" justifyContent="center">
                <DevisIcon size='1rem' />
                <Typography variant="P12_Lh130" sx={{  color:palette.text_light.main }}>
                    {props.type}
                </Typography>
                <Typography variant="P12_Lh130">
                    {props.value}
                </Typography>
            </Stack>
            <Stack direction="row" gap={0.5} alignItems="center" justifyContent="center">
                <Typography variant="P12_Lh130" >
                    45%
                </Typography>
                <GoingUpIcon size='1rem' color={palette.success.main} />
            </Stack>
        </Stack>
    )
}