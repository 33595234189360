import { BACKEND_BASE_URL } from "../../consts";
import axios from "axios";
import {
  createFormData,
  extractErrorMessage,
  getAuthHeaders,
} from "../../helpers";
import toast, { Toaster } from "react-hot-toast";
import { IndexResult } from "../@types";

export class GenricService {
  static async fetchInfinit<T>(
    route: string,
    pageParam: any | null
  ): Promise<IndexResult<T>> {
    let url = `${BACKEND_BASE_URL}${route}`;
    const AuthHeaders = getAuthHeaders();

    try {
      return (
        await axios.get(url, { params: pageParam, headers: { ...AuthHeaders } })
      ).data;
    } catch (err: any) {
      toast.error(err.message);
      throw new Error(...err);
    }
  }

  static async fetch<T>(route: string, params: any): Promise<T> {
    const url = `${BACKEND_BASE_URL}${route}`;
    const AuthHeaders = getAuthHeaders();
    try {
      return (await axios.get(url, { params, headers: { ...AuthHeaders } }))
        .data;
    } catch (err: any) {
      toast.error(err.message);
      throw new Error(...err);
    }
  }

  static async fetchOneById<T>(route: string, id: number): Promise<T> {
    const url = `${BACKEND_BASE_URL}${route}/${id}`;
    const AuthHeaders = getAuthHeaders();
    try {
      return (await axios.get(url, { headers: { ...AuthHeaders } })).data;
    } catch (err: any) {
      toast.error(err.message);
      throw Error(...err);
    }
  }

  static async fetchOneByParams<T>(route: string, params: any): Promise<T> {
    const url = `${BACKEND_BASE_URL}${route}`;
    const AuthHeaders = getAuthHeaders();
    try {
      return (await axios.get(url, { params, headers: { ...AuthHeaders } }))
        .data;
    } catch (err: any) {
      toast.error(err.message);
      throw Error(...err);
    }
  }

  static async PostAction<REQ_BODY, RES_BODY>(
    route: string,
    data: REQ_BODY
  ): Promise<RES_BODY> {
    const url = `${BACKEND_BASE_URL}${route}`;
    const AuthHeaders = getAuthHeaders();

    try {
      const response = await axios.post(url, data, {
        headers: { ...AuthHeaders },
      });
      toast.success("Fait");
      return response.data;
    } catch (err: any) {
      toast.error(err.message);
      throw Error(...err);
    }
  }

  static async PostOne<REQ_BODY, RES_BODY>(
    route: string,
    data: REQ_BODY
  ): Promise<RES_BODY> {
    const url = `${BACKEND_BASE_URL}${route}`;
    const AuthHeaders = getAuthHeaders();

    try {
      const response = await axios.post(url, data, {
        headers: { ...AuthHeaders },
      });
      toast.success("créé");
      return response.data;
    } catch (err: any) {
      toast.error(err.message);
      throw Error(...err);
    }
  }

  static async PutOne<REQ_BODY, RES_BODY>(
    route: string,
    data: REQ_BODY
  ): Promise<RES_BODY> {
    const url = `${BACKEND_BASE_URL}${route}`;
    const AuthHeaders = getAuthHeaders();
    console.log({ put: data });
    try {
      const response = await axios.put(url, data, {
        headers: { ...AuthHeaders },
      });
      toast.success("updated");
      return response.data;
    } catch (err: any) {
      toast.error(err.message);
      throw Error(...err);
    }
  }

  static async PatchOne<REQ_BODY, RES_BODY>(
    route: string,
    data: REQ_BODY
  ): Promise<RES_BODY> {
    const url = `${BACKEND_BASE_URL}${route}`;
    const AuthHeaders = getAuthHeaders();
    console.log({ patch: data });
    try {
      const response = await axios.patch(url, data, {
        headers: { ...AuthHeaders },
      });
      toast.success("updated");
      return response.data;
    } catch (err: any) {
      toast.error(err.message);
      throw Error(...err);
    }
  }

  static async DeleteOne<REQ_BODY, RES_BODY>(
    route: string,
    id: number
  ): Promise<RES_BODY> {
    const url = `${BACKEND_BASE_URL}${route}/${id}`;
    const AuthHeaders = getAuthHeaders();
    try {
      const response = await axios.delete(url, {
        headers: { ...AuthHeaders },
      });
      toast.success("deleted");
      return response.data;
    } catch (err: any) {
      toast.error(err.message);
      throw Error(...err);
    }
  }
}
