import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import toast from "react-hot-toast";
import { BACKEND_BASE_URL } from "../../consts";
import { createFormData, getAuthHeaders } from "../../helpers";
import { FileDescription } from "../@types";

export class FileUploadService {
  static async uploadOne(file: any): Promise<FileDescription> {
    const route = `/techniciensApi/v1/uploadFile`;
    let url = `${BACKEND_BASE_URL}${route}`;
    const AuthHeaders = getAuthHeaders();
    try {
      const response = await axios.post(url, createFormData({ files: file }), {
        headers: { ...AuthHeaders, "content-type": "multipart/form-data" },
      });
      toast.success(" file uploaded");
      return response.data;
    } catch (err: any) {
      toast.error(err.message);
      throw Error(...err);
    }
  }

  static uploadMany(files: any[]): Promise<FileDescription[]> {
    return Promise.all(files.map(FileUploadService.uploadOne));
  }
}

export const useUplaod = (onUploaded?: (file: FileDescription) => void) => {
  return useMutation(FileUploadService.uploadOne, {
    onSuccess: (data) => {
      onUploaded && onUploaded(data);
    },
    onError: (err: any) => {},
  });
};


export const useUploadMany = (onUploaded?: (files: FileDescription[]) => void) => {
  return useMutation(FileUploadService.uploadMany, {
    onSuccess: (data) => {
      onUploaded && onUploaded(data);
    },
    onError: (err: any) => {},
  });
};
