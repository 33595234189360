import {
  CreateEtudeRequest,
  CreateEtudeResponse,
  EtudeFaisabilite,
  UpdateEtudeRequest,
  UpdateEtudeResponse,
} from "../@types";
import { GenricService } from "./geniric.service";

export class EtudeFaisabilityService {
  static async fetchAll(): Promise<EtudeFaisabilite> {
    const route = `/techniciensApi/v1/etudeFaisabilites`;
    return GenricService.fetch<EtudeFaisabilite>(route, {});
  }

  static async fetchOne(id: number): Promise<EtudeFaisabilite> {
    const route = `/techniciensApi/v1/etudeFaisabilites`;
    return GenricService.fetchOneById<EtudeFaisabilite>(route, id);
  }

  static async createOne(
    data: CreateEtudeRequest
  ): Promise<CreateEtudeResponse> {
    const route = `/techniciensApi/v1/etudeFaisabilites`;
    return GenricService.PostOne<CreateEtudeRequest, CreateEtudeResponse>(
      route,
      data
    );
  }

  static async updateOne(
    data: UpdateEtudeRequest
  ): Promise<UpdateEtudeResponse> {
    const route = `/techniciensApi/v1/etudeFaisabilites/${data.id}`;
    return GenricService.PatchOne<UpdateEtudeRequest, UpdateEtudeResponse>(
      route,
      data
    );
  }
}
