import { useState } from "react";


export function useActionStatus<TResult = any>() {
    const [result, setResult] = useState<TResult | undefined>(undefined);
    const [error, setError] = useState<any>(undefined);
    const [isPending, setIsPending] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const act = async (fun: () => Promise<TResult>) => {
        if(isDone) {
            return;
        }
        setIsPending(true);
        try {
            const result = await fun();
            setResult(result);
            setIsDone(true);
        } catch (error) {
            setError(error);
            setIsDone(false);
        } finally {
            setIsPending(false);
        }
    };

    return { result, error, isPending, isDone, act };
}