import { ModalWraper } from "@/components/screens/projets/components/panels/activity-panel/modals/ModalWraper"; // TODO : remove this dependance
import { Devi, Projet } from "@/services_api";
import { useAxiosInstance } from "@clean/common/api";
import { AmountInput } from "@clean/common/forms/AmountInput";
import { formSchemaToRule } from "@clean/common/forms/helpers";
import { useActionStatus } from "@clean/common/useActionStatus";
import { Alert, Button, Form, Input, Result, Select, Spin } from "antd";
import moment from "moment";
import { useEffect } from "react";
import * as yup from "yup";
import { submitSendPaymentRequest } from "./service";

export interface SendPaymentRequestModalProps {
    onClose: () => void;
    projet: Projet & { id: number };
    availableDevis: Devi[];
}

export const SendPaymentRequestModal = (props: SendPaymentRequestModalProps) => {

    const { onClose, projet, availableDevis } = props;

    const [form] = Form.useForm();

    const selectedDeviId = Form.useWatch('deviId', form);
    const selectedDevi = availableDevis.find((devi) => devi.id === selectedDeviId);

    useEffect(() => {
        form.setFieldsValue({
            totalAmount: selectedDevi?.totalAmount ?? undefined,
        });
    }, [selectedDeviId]);

    const formSchema = yup.object().shape({
        deviId: yup.number().required('Veuillez choisir un devis'),
        totalAmount: yup.number().required('Veuillez saisir un montant').min(1, 'Le montant doit être positif').max(selectedDevi?.totalAmount ?? 0, 'Le montant ne peut pas être supérieur au montant du devis'),
        description: yup.string(),
    });

    const rule = formSchemaToRule(formSchema, form.getFieldsValue);

    const axiosInstance = useAxiosInstance();
    const { result, error, isPending, isDone, act } = useActionStatus();

    const handleSubmit = () => {
        const values = form.getFieldsValue();
        const validatedValues = formSchema.validateSync(values);

        act(
            () => submitSendPaymentRequest({
                ...validatedValues,
                projetId: projet.id,
            }, axiosInstance)
        );
    };

    return (
        <ModalWraper
            title="Envoyer une demande de paiement"
            saveBtnText="Envoyer"
            cancelBtnText="Annuler"
            isOpen={true}
            onSave={handleSubmit}
            onCancel={onClose}
        >

            {
                isDone ? (
                    <Result
                        status="success"
                        title="La demande de paiement vient d'être envoyée"
                        extra={[
                            <Button type="primary" key="console" onClick={onClose}>
                                Fermer
                            </Button>,
                        ]}
                    />
                ) : (
                    <Spin spinning={isPending}>

                        {error && (
                            <Alert
                                message="Erreur"
                                description={'Une erreur est survenue lors de l\'envoi.'/*error.message*/}
                                type="error"
                                showIcon
                                style={{ marginBottom: 16 }}
                            />
                        )}
                        
                        <Form
                            form={form}
                            initialValues={{
                                projetId: projet.id,
                                deviId: availableDevis.length > 0 ? availableDevis[0].id : ("" as any),
                                totalAmount: 0,
                                description: "",
                            }}
                            onFinish={handleSubmit}
                            layout="vertical"
                            size="large"
                        >
                            <Form.Item
                                name="deviId"
                                label="Devis"
                                rules={[rule]}
                            >
                                <Select>
                                    {availableDevis.map((devi) => (
                                        <Select.Option key={devi.id} value={devi.id}>
                                            Devis n°{devi.id} — {devi.totalAmountString} — {moment(devi.createdAt).format('LLLL')}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            {/*selectedDevi && (<>
                                <div>
                                    <p>Montant total du devis sélectionné : {selectedDevi.totalAmountString}</p>
                                </div>
                            </>)*/}

                            <Form.Item
                                name="totalAmount"
                                label="Montant à payer"
                                rules={[rule]}
                            >
                                <AmountInput
                                    max={selectedDevi?.totalAmount ?? undefined}
                                />
                            </Form.Item>

                            <Form.Item
                                name="description"
                                label="Objet"
                                rules={[rule]}
                            >
                                <Input.TextArea />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Envoyer la demande de paiement
                                </Button>
                            </Form.Item>

                        </Form>
                    </Spin>
                )

            }
            

        </ModalWraper>
    );

};