import React, { createContext, FC, useContext, useMemo, useState } from 'react'


type MultiModalcontextType = {
    modalKey: string | null,
    setModalKey: React.Dispatch<React.SetStateAction<string | null>>
}
const MultiModalcontext = createContext<MultiModalcontextType>({
    modalKey: null,
    setModalKey: ()=>{}
})
type MultiModalProviderProps = {
    children: React.ReactNode
}
export const MultiModalProvider: FC<MultiModalProviderProps> = ({ children }) => {
    const [modalKey, setModalKey] = useState<string | null>(null)
    const contextValue: MultiModalcontextType = useMemo(() => (
        {
            modalKey,
            setModalKey,
        }
    ), [modalKey])
    return (
        <MultiModalcontext.Provider value={contextValue}>
            {children}
        </MultiModalcontext.Provider>
    )
}

export const useMultiModal = () => useContext(MultiModalcontext)
