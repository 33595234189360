import { Divider, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Box, TextareaAutosize, Typography, useTheme } from '@mui/material'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useFormik } from 'formik'
import { CreateEtudeRequest, EtudeFaisabilite, FileDescription } from '../../../../../../../services_api'
import { projectsKeys } from '../../../../../../../services_api/keys'
import { EtudeFaisabilityService, useUplaod } from '../../../../../../../services_api/services'
import { LoadingLayer } from '../../../../../../util'
import { ImagesPicker } from '../../../ImagesPicker'
import { RealiseParBlock } from '../../../RealiseParBlock'
import { MyModalProps } from './@types'
import { ModalWraper } from './ModalWraper'

type EtudeFaisabilteModalProps = MyModalProps & { data?: EtudeFaisabilite }

export const EtudeFaisabilteModal = (props: EtudeFaisabilteModalProps) => {
  const {
    onclose = () => { },
    isOpen = false,
    data,
    readonly = false,
    projetId,
    action
  } = props

  const { palette } = useTheme()



  const { values, handleChange, errors, handleSubmit, setFieldValue } = useFormik<CreateEtudeRequest>({
    initialValues: {
      projetId: projetId,
      text: data?.text || "",
      attachedFiles: data?.attachedFiles || [],
      isFaisable: data?.isFaisable ?? true
    },
    onSubmit: (values) => {
      switch (action) {
        case "create":
          create(values); break;
        case "update":
          if (data?.id) update({ ...values, id: data.id });
          break;
        default: break
      }
    }
  })
  console.log(values.attachedFiles);




  const queryClient = useQueryClient()
  const refreshProject = () => {
    queryClient.invalidateQueries([projectsKeys.for_many, projetId])
  }
  const { mutate: create, isLoading: isCreating } = useMutation(EtudeFaisabilityService.createOne, {
    onSuccess: (data) => {
      refreshProject()
      onclose()
    },
    onError: (err: any) => {
    },
  })

  const { mutate: update, isLoading: isUpdating } = useMutation(EtudeFaisabilityService.updateOne, {
    onSuccess: (data) => {
      refreshProject()
      onclose()
    },
    onError: (err: any) => {
    },
  })



  if (!isOpen) return <></>
  return (
    <ModalWraper
      title="Etude de faisabilité"
      cancelBtnText={readonly ? "Fermer" : "Annuler"}
      onCancel={onclose}
      onSave={handleSubmit}
      isOpen={isOpen}
      disableSave={readonly}
      padding={0}
    >
      <LoadingLayer open={isCreating || isUpdating} />
      <Stack paddingY={1.5} gap={1.5}>

        <FormControl sx={{ paddingX: 1.5 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <Typography variant="P14_Lh130" sx={{ display: { xs: 'none', sm: 'inline' }, color: palette.text_light2.main }}>
              Faisabilité
            </Typography>
            <RadioGroup
              aria-labelledby="rdv-radio-buttons-group-label"
              defaultValue="installation"
              name="radio-buttons-group"
              sx={{ dieplay: "flex", flexDirection: "row", gap: 3, width: "100%" }}
              value={values.isFaisable}
              onChange={handleChange("isFaisable")}
            >
              <FormControlLabel disabled={readonly} value={true} control={<Radio color="success" />} label="Faisable" />
              <FormControlLabel disabled={readonly} value={false} control={<Radio color="warning" />} label="Non faisable" />
            </RadioGroup>
          </Stack>
        </FormControl>

        <Box sx={{ position: "relative", paddingX: 1.5, }}>
          <TextareaAutosize
            aria-label="minimum height"
            minRows={7}
            value={values.text}
            onChange={handleChange("text")}
            readOnly={readonly}
            placeholder="Veuillez noter toutes les informations nécessaires à la réalisation du projet et du devis (par exemple : nombre de mètres de câble à tirer, viabilité de la prise terre, remarques du client, nombre de trous à percer, etc.)"
            style={{
              width: "100%",
              backgroundColor: palette.bg_primary.main,
              borderRadius: "0.5rem",
              border: "none",
              outline: "none",
              color: palette.text_light.main,
              padding: " 1rem",
              boxSizing: "border-box"
            }}
          />
        </Box>
        <Divider />
        <Stack gap={1} paddingX={1.5}>
          <Typography variant="P14_Lh130" style={{ color: palette.text_light.main }}>
            Pieces jointes (Veuillez ajouter au moins 6 photos minimum à cette étude de faisabilité)
          </Typography>
          <ImagesPicker
            attachedFiles={values?.attachedFiles}
            updateParentData={setFieldValue}
            readOnly={readonly}
          />
          <RealiseParBlock user={data?.installateurUser} />
        </Stack>

      </Stack>

    </ModalWraper>
  )
}
