import { ModalWraper } from "@/components/screens/projets/components/panels/activity-panel/modals/ModalWraper"; // TODO : remove this dependance
import { Devi, Projet } from "@/services_api";
import { BookOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useAxiosInstance } from "@clean/common/api";
import { AmountInput } from "@clean/common/forms/AmountInput";
import { formSchemaToRule } from "@clean/common/forms/helpers";
import { formatAmount } from "@clean/common/helpers";
import { useActionStatus } from "@clean/common/useActionStatus";
import { Alert, Button, DatePicker, Divider, Form, Input, InputNumber, Modal, Result, Select, Spin, Table } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { fetchDeviTemplates, fetchProducts, submitCreateDevi } from "./service";

export interface CreateDeviModalProps {
    onClose: () => void;
    projet: Projet & { id: number };
}

export const CreateDeviModal = (props: CreateDeviModalProps) => {

    const { onClose, projet, } = props;
    
    const axiosInstance = useAxiosInstance();

    let [deviTemplates, setDeviTemplates] = useState<any[] | null>(null);
    useEffect(() => {
        fetchDeviTemplates(projet.id, axiosInstance).then(data => setDeviTemplates(data.rows));
    }, []);

    let [products, setProducts] = useState<any[] | null>(null);
    useEffect(() => {
        fetchProducts(projet.id, axiosInstance).then(data => setProducts(data.rows));
    }, []);

    const [form] = Form.useForm();

    const items = Form.useWatch('items', form);
    const vatRatePercentage = Form.useWatch('vatRatePercentage', form);
    console.log('items', items);
    let preTaxAmount = items?.map((item: any) => (item?.quantity ?? 1) * (item?.unitAmount ?? 0)).reduce((acc: number, item: number) => acc + item, 0) ?? 0;
    let totalAmount = Math.round(preTaxAmount*(1+vatRatePercentage/100));

    const date = Form.useWatch('date', form);

    useEffect(() => {
        if(date) {
            form.setFieldValue(
                'offreValableJusquAu',
                dayjs(date).clone().add(15, 'days')
            );
        }
    }, [date]);

    const formSchema = yup.object().shape({
        items: yup.array().of(
            yup.object().shape({
                title: yup.string().required('Veuillez saisir un titre'),
                text: yup.string(),
                quantity: yup.number().required('Veuillez saisir une quantité').min(1, 'La quantité doit être positive'),
                unitAmount: yup.number().required('Veuillez saisir un prix unitaire HT').min(1, 'Le prix unitaire doit être positif'),
            })
        ).min(1, 'Veuillez saisir au moins un article').required('Veuillez saisir au moins un article'),
        vatRatePercentage: yup.number().oneOf([5.5, 20]).required('Veuillez choisir un taux de TVA'),
        date: yup.date().required('Veuillez saisir une date'),
        offreValableJusquAu: yup.date().required('Veuillez saisir une date').min(yup.ref('date'), 'La date de validité doit être ultérieure à la date du devis'),
        objet: yup.string().required('Veuillez saisir un objet'),
        commentaire: yup.string(),
        firstInstallmentPercentage: yup.number().required('Veuillez saisir un pourcentage').min(0).max(100),
    });

    const rule = formSchemaToRule(formSchema, form.getFieldsValue);

    const { result, error, isPending, isDone, act } = useActionStatus();

    const handleSubmit = () => {
        const values = form.getFieldsValue();
        const validatedValues = formSchema.validateSync(values);

        act(
            () => submitCreateDevi({
                ...validatedValues,
                projetId: projet.id,
                date: dayjs(validatedValues.date).format('YYYY-MM-DD'),
                offreValableJusquAu: dayjs(validatedValues.offreValableJusquAu).format('YYYY-MM-DD'),
            }, axiosInstance)
        );
    };

    const [isCatalogModalOpen, setIsCatalogModalOpen] = useState(false);

    return (
        <ModalWraper
            title="Créer un devis"
            saveBtnText="Créer"
            cancelBtnText="Annuler"
            isOpen={true}
            onSave={handleSubmit}
            onCancel={onClose}
        >

            {
                isDone ? (
                    <Result
                        status="success"
                        title="Le devis vient d'être créé"
                        extra={[
                            <Button type="primary" key="console" onClick={onClose}>
                                Fermer
                            </Button>,
                        ]}
                    />
                ) : (
                    <Spin spinning={isPending}>

                        {error && (
                            <Alert
                                message="Erreur"
                                description={'Une erreur est survenue lors de la création.'/*error.message*/}
                                type="error"
                                showIcon
                                style={{ marginBottom: 16 }}
                            />
                        )}
                        
                        <Form
                            form={form}
                            initialValues={{
                                vatRatePercentage: 5.5,
                                date: dayjs(),
                                firstInstallmentPercentage: 0,
                                items: [], //TODO: temp
                            }}
                            onFinish={handleSubmit}
                            layout="vertical"
                            size="large"
                        >
                                    
                            <Form.List name="items" rules={[rule]}>
                                {(rows, { add, remove }) => <>
                                
                                    {rows.map((row) => (
                                        <React.Fragment key={row.key}>
                                            <Divider>Produit n°{row.name+1}</Divider>
                                            <Form.Item
                                                label="Titre du produit"
                                                name={[row.name, 'title']}
                                                rules={[rule]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                label="Description"
                                                name={[row.name, 'text']}
                                                rules={[rule]}
                                            >
                                                <Input.TextArea />
                                            </Form.Item>
                                            <Form.Item
                                                label="Quantité"
                                                name={[row.name, 'quantity']}
                                                rules={[rule]}
                                            >
                                                <InputNumber />
                                            </Form.Item>
                                            <Form.Item
                                                label="Prix unitaire HT"
                                                name={[row.name, 'unitAmount']}
                                                rules={[rule]}
                                            >
                                                <AmountInput />
                                            </Form.Item>

                                            <div>
                                                <Button onClick={() => remove(row.name)}>
                                                    <MinusOutlined /> Retirer ce produit
                                                </Button>
                                            </div>
                                        </React.Fragment>
                                    ))}

                                    <Divider />

                                    <div style={{ marginTop: 16 }}>
                                        <Button onClick={ () => { add(); } }>
                                            <PlusOutlined /> Ajouter un produit
                                        </Button>
                                    </div>

                                    <div style={{ marginTop: 16 }}>
                                        <Button onClick={ () => { setIsCatalogModalOpen(true); } }>
                                            <BookOutlined /> Voir le catalogue
                                        </Button>
                                        <Modal
                                            title="Catalogue"
                                            open={isCatalogModalOpen}
                                            footer={null}
                                            onCancel={() => setIsCatalogModalOpen(false)}
                                        >
                                            Ajouter un produit :
                                            <Select
                                                style={{ width: "100%", marginBottom: 16 }}
                                                allowClear
                                                placeholder="Produits"
                                                onChange={
                                                    (value) => {
                                                        let product = products?.find(product => product.id === value);
                                                        add({
                                                            title: product.title,
                                                            text: product.description,
                                                            unitAmount: product.price,
                                                            quantity: 1,
                                                        });
                                                        setIsCatalogModalOpen(false);
                                                    }
                                                }
                                            >
                                                {(products??[]).map(
                                                    products => <Select.Option value={products.id}>{products.title}</Select.Option>
                                                )}
                                            </Select>

                                            Ajouter un ensemble de produits depuis un template :
                                            <Select
                                                style={{ width: "100%", marginBottom: 16 }}
                                                allowClear
                                                placeholder="Templates"
                                                onChange={
                                                    (value) => {
                                                        let deviTemplate = deviTemplates?.find(deviTemplate => deviTemplate.id === value);
                                                        deviTemplate?.items?.forEach(item => {
                                                            add(item);
                                                        });
                                                        setIsCatalogModalOpen(false);
                                                    }
                                                }
                                            >
                                                {(deviTemplates??[]).map(
                                                    deviTemplate => <Select.Option value={deviTemplate.id}>{deviTemplate.title}</Select.Option>
                                                )}
                                            </Select>
                                        </Modal>
                                    </div>

                                </>}
                            </Form.List>

                            <Divider />

                            {
                                items?.length > 0 && (
                                    <div>
                                        <h3>Total HT : {formatAmount(preTaxAmount)}</h3>
                                    </div>
                                )
                            }

                            <Form.Item
                                name="vatRatePercentage"
                                label="Taux TVA"
                                rules={[rule]}
                            >
                                <Select>
                                    <Select.Option value={5.5}>5.5%</Select.Option>
                                    <Select.Option value={20}>20%</Select.Option>
                                </Select>
                            </Form.Item>
                            
                            {
                                items?.length > 0 && vatRatePercentage && (
                                    <div>
                                        <h3>Total TTC : {formatAmount(totalAmount)}</h3>
                                    </div>
                                )
                            }

                            <Form.Item
                                name="date"
                                label="Date"
                                rules={[rule]}
                            >
                                <DatePicker />
                            </Form.Item>
                            <Form.Item
                                name="offreValableJusquAu"
                                label="Offre valable jusqu'au"
                                rules={[rule]}
                            >
                                <DatePicker />
                            </Form.Item>
                            <Form.Item
                                name="objet"
                                label="Objet"
                                rules={[rule]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="commentaire"
                                label="Commentaires"
                                rules={[rule]}
                            >
                                <Input.TextArea rows={4}/>
                            </Form.Item>
                            <Form.Item
                                name="firstInstallmentPercentage"
                                label="Taux d'acompte"
                                rules={[rule]}
                            >
                                <InputNumber min={0} max={100} step={0.1} addonAfter="%" />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Créer le devis
                                </Button>
                            </Form.Item>

                        </Form>
                    </Spin>
                )

            }
            

        </ModalWraper>
    );

};