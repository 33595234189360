import { ListItemButton, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'

type ContactItemProps = {
    icon: React.ReactNode
    size?: string
    title: string
    borderColor: string
    href?: string,
    onClick?: () => void
}
export const ContactItem = React.memo((props: ContactItemProps) => {
    const { icon, size = "4rem", title, borderColor, onClick, href } = props
    const { palette } = useTheme()

    return (
        <a
            style={{ cursor: "pointer", textDecoration: "none" }}
            href={href}
            target="_blank"
            onClick={(e: any) => {
                if (!href) e.preventDefault();
                onClick && onClick()
            }}
        >
            <Stack gap={0.5} alignItems="center"  >
                <ListItemButton
                    sx={{
                        border: "1px solid",
                        borderColor: borderColor,
                        borderRadius: "100%",
                        width: size,
                        height: size,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    {icon}
                </ListItemButton>

                <Typography variant="P12_Lh130" sx={{ color: palette.text_light2.main, maxWidth: size, textAlign: "center" }}>
                    {title}
                </Typography>
            </Stack>
        </a >
    )
})

